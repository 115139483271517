import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  OMWLogoWhite,
  fileUpload,
  EditIcon
} from "../../constants/Images";
import LanguageDropdown from "../../components/shared/TranslationDropdown";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CircularProgress, } from '@mui/material';
import BasicButton from '../../components/shared/Buttons/Basic';
import './index.css';
import InputTypeText from '../../components/shared/Input/Basic/InputTypeText';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

import {
  deleteDirector,
  setDocumentSubmitFormData,
  updateDirector,
} from '../../redux/toolkit/documentSubmit/reducer';
import {
  fetchCompanyInfoByCVR,
  fetchUserInfo,
  submitDocuments,
  submitSection1,
  submitSection2,
  submitSection3,
  submitSection4,
  submitSection5,
  submitSection6,
  submitSection7,
  updateCompanyInfoField,
  updateDocuments,
} from '../../redux/toolkit/documentSubmit/operation';
import { AppDispatch, RootState } from '../../redux/store';
import { useTranslation, withTranslation } from "react-i18next";
import {
  DirectorInformation,
  DocumentSubmitFormData,
} from '../../redux/toolkit/documentSubmit/interface';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5Page from './Section5';
import DocumentUpload from './DocumentUpload';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import Section6 from './Section6';
import Section7 from './Section7';
import Swal from 'sweetalert2';

const FORM_DATA_KEY = 'documentSubmitFormData';
const STEP_KEY = 'activeDocumentStep';
const DocumentSubmit = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userId: any = localStorage.getItem("userId");
  const formData = useSelector((state: RootState) => state.documentSubmit);
  const [activeDocumentStep, setActiveDocumentStep] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [editFieldName, setEditFieldName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [cvrLoading, setCvrLoading] = useState(false);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [questionnaireStep, setQuestionnaireStep] = useState(0);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [callInfo, setCallInfo] = useState(false);
  const [editableField, setEditableField] = useState<string | null>(null);
  const [fieldBackup, setFieldBackup] = useState<string | null>(null);
  const [personalTaxStatement, setPersonalTaxStatement] = useState<File | null>(null);
  const [bankStatement, setBankStatement] = useState<File | null>(null);
  const [proofNatureBusinessFile, setProofNatureBusinessFile] = useState<File | null>(null);
  const [businessRegCertificateFile, setBusinessRegCertificateFile] = useState<File | null>(null);
  const [additionalDocuments, setAdditionalDocuments] = useState<File[]>([]);
  const [refreshData, setRefreshData] = useState(false);
  const [callUpdateForDocument, setCallUpdateForDocument] = useState<boolean>(false);

  const defaultFormData: DocumentSubmitFormData = {
    onboardingProgress: 0,
    onboardingStatus: '',
    page1: {
      companyType: "",
      companyName: "",
      businessRegistrationNumber: "",
      countryOfIncorporation: "",
      businessRegistryLink: "",
      businessDescription: "",
      expectedAnnualRevenue: "",
      selectedCurrency: '',
      numberOfEmployees: 0
    },
    page2: {
      remitters: [],
      beneficiaries: [],
    },
    page3: {
      marketingDescription: "",
      websiteUrl: "",
      facebookPage: "",
      instagramName: "",
    },
    page4: {
      incomingPaymentsPerMonth: '',
      outgoingPaymentsPerMonth: '',
      monthlyIncomingPayments: 0,
      monthlyOutgoingPayments: 0
    },
    page5: {
      incomingPaymentCountries: [],
      outgoingPaymentCountries: [],
      incomingCurrencies: [],
      outgoingCurrencies: [],
    },
    page6: {
      requestBusinessCard: false,
      expectedMonthlyCardSpend: '',
      cardholder: "",
      cardholderResidenceCountries: [],
    },
    page7: {
      owningCompanyDetails: {
        companyName: "",
        countryOfRegistration: "",
        businessRegistrationNumber: ""
      },
      isDirectorSoleOwner: false,
      isUboSameAsDirector: false,
      directors: [],
      beneficialOwners: [],
      hasMultipleOwners: false,
      isOwnedByAnotherCompany: false,
    },
    page8: {
      personalTaxStatement: null,
      bankStatement: null,
      proofOfNatureOfBusiness: null,
      businessRegistrationCertificate: null,
      additionalDocument: null,
      isSubmitted: false,
    }
  };

  const [initialFormData, setInitialFormData] = useState<DocumentSubmitFormData>(defaultFormData);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [companyRegistrationNumber, setCompanyRegistrationNumber] = useState(formData?.page1?.businessRegistrationNumber);
  const [directors, setDirectors] = useState<DocumentSubmitFormData['page7']['directors']>(
    formData?.page7?.directors?.length > 0
      ? formData.page7.directors
      : [
        {
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          address: {
            street: '',
            postalCode: '',
            city: '',
            country: '',
          },
        },
      ]
  );
  const [beneficialOwners, setBeneficialOwners] = useState<DocumentSubmitFormData['page7']['beneficialOwners']>(
    formData?.page7?.beneficialOwners?.length > 0
      ? formData.page7.beneficialOwners
      : [
        {
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          ownershipPercentage: '',
        },
      ]
  );

  const handleAddDirector = () => {
    setDirectors([
      ...directors,
      {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        address: {
          street: '',
          postalCode: '',
          city: '',
          country: '',
        },
      },
    ]);
  };
  const handleAddBeneficialOwner = () => {
    setBeneficialOwners([
      ...beneficialOwners,
      {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        ownershipPercentage: '',
      },
    ]);
  };

  const validateFields = (currentStep: number): { [key: string]: string } => {
    const newErrors: { [key: string]: string } = {};

    if (currentStep === 0) {
      if (!formData?.page1?.companyName) {
        newErrors.companyName = "Please enter the company name";
      }
      if (!formData?.page1?.businessRegistrationNumber) {
        newErrors.businessRegistrationNumber = "Please enter the business registration number";
      }
      if (!formData?.page1?.countryOfIncorporation) {
        newErrors.countryOfIncorporation = "Please enter the country of incorporation";
      }
      if (!formData?.page1?.businessRegistryLink) {
        newErrors.businessRegistryLink = "Please enter the Business Registry Link";
      }
      if (!formData?.page1?.businessDescription) {
        newErrors.businessDescription = "Please enter the business description";
      }
      if (!formData?.page1?.expectedAnnualRevenue) {
        newErrors.expectedAnnualRevenue = "Please select the expected annual revenue";
      }
      if (!formData?.page1?.numberOfEmployees) {
        newErrors.numberOfEmployees = "Please enter the number of employee";
      }
    }

    if (currentStep === 1) {
      const isAnyFieldFilled =
        formData?.page2?.remitters?.some((remitter) => remitter) ||
        formData?.page2?.beneficiaries?.some((beneficiary) => beneficiary);

      if (!isAnyFieldFilled) {
        newErrors.general = "Please fill at least one field to proceed.";
      }
    }

    if (currentStep === 2) {
      if (!formData?.page3?.marketingDescription) {
        newErrors.marketingDescription = "Please enter marketing description";
      }
    }

    if (currentStep === 3) {
      if (!formData?.page4?.incomingPaymentsPerMonth) {
        newErrors.incomingPaymentsPerMonth = "Please select incoming monthly payments";
      }
      if (!formData?.page4?.outgoingPaymentsPerMonth) {
        newErrors.outgoingPaymentsPerMonth = "Please select outgoing monthly payments";
      }
      if (!formData?.page4?.monthlyIncomingPayments) {
        newErrors.monthlyIncomingPayments = "Please select incoming monthly payments in EUR";
      }
      if (!formData?.page4?.monthlyOutgoingPayments) {
        newErrors.monthlyOutgoingPayments = "Please select outgoing monthly payments in EUR";
      }
    }

    if (currentStep === 4) {
      if (!formData?.page5?.incomingPaymentCountries) {
        newErrors.incomingPaymentCountries = "Please select country for incoming payment";
      }
      if (!formData?.page5?.outgoingPaymentCountries) {
        newErrors.outgoingPaymentCountries = "Please select country for outgoing payment";
      }
      if (!formData?.page5?.incomingCurrencies) {
        newErrors.incomingCurrencies = "Please select currency for incoming payment";
      }
      if (!formData?.page5?.outgoingCurrencies) {
        newErrors.outgoingCurrencies = "Please select currency for outgoing payment";
      }
    }
    if (currentStep === 5) {
      if (!formData?.page6?.expectedMonthlyCardSpend) {
        newErrors.expectedMonthlyCardSpend = "Please select monthly card spending";
      }
      if (!formData?.page6?.cardholder) {
        newErrors.cardholder = "Please select card holder option";
      }
      if (!formData?.page6?.cardholderResidenceCountries) {
        newErrors.cardholderResidenceCountries = "Please select residence countries for card holder";
      }
    }

    if (currentStep === 6) {
      if (formData?.page7?.isOwnedByAnotherCompany === undefined) {
        newErrors.isOwnedByAnotherCompany = "Please select do company have multiple owners";
      }
    }

    return newErrors;
  };

  const isAddressField = (field: string): field is keyof DirectorInformation["address"] => {
    return ["street", "postalCode", "city", "country"].includes(field);
  };

  const handleDirectorInputChange = (
    index: number,
    field: keyof DirectorInformation | keyof DirectorInformation["address"],
    value: string
  ) => {
    const updatedDirectors = [...directors];

    if (field !== "address" && field in updatedDirectors[index]) {
      updatedDirectors[index][field as Exclude<keyof DirectorInformation, "address">] = value;
    }
    else if (field in updatedDirectors[index].address) {
      updatedDirectors[index].address[field as keyof DirectorInformation["address"]] = value;
    }

    setDirectors(updatedDirectors);
    dispatch(updateDirector({ index, data: updatedDirectors[index] }));
  };

  const handleBeneficialOwnerInputChange = (
    index: number,
    field: keyof typeof beneficialOwners[0],
    value: string
  ) => {
    const updatedBeneficialOwners = [...beneficialOwners];
    updatedBeneficialOwners[index] = {
      ...updatedBeneficialOwners[index],
      [field]: value,
    };
    setBeneficialOwners(updatedBeneficialOwners);
  };


  useEffect(() => {
    const isFieldFilled = (value: any) =>
      value !== null && value !== undefined && value !== "" && (!Array.isArray(value) || value.length > 0);
    const loadCompanyInfo = async () => {
      setLoading(true);
      try {
        const response = await dispatch(fetchUserInfo(userId)).unwrap();
        const populatedResponse = {
          ...defaultFormData,
          ...response,
          page1: { ...defaultFormData.page1, ...response.page1 },
          page2: { ...defaultFormData.page2, ...response.page2 },
          page3: { ...defaultFormData.page3, ...response.page3 },
          page4: { ...defaultFormData.page4, ...response.page4 },
          page5: { ...defaultFormData.page5, ...response.page5 },
          page6: { ...defaultFormData.page6, ...response.page6 },
          page7: { ...defaultFormData.page7, ...response.page7 },
          page8: { ...defaultFormData.page8, ...response.page8 },
        };
        setInitialFormData(populatedResponse);
        dispatch(setDocumentSubmitFormData(response));
        if (response?.page7?.directors?.length > 0) {
          setDirectors(response.page7.directors);
        }

        const startStep = 0;
        let startQuestionnaireStep = 0;
        let startActiveDocumentStep = 0;
        if (
          isFieldFilled(response?.page1?.companyName) &&
          isFieldFilled(response?.page1?.businessRegistrationNumber) &&
          isFieldFilled(response?.page1?.countryOfIncorporation)
        ) {
          startQuestionnaireStep = 1;
        }
        if (
          startQuestionnaireStep >= 1 &&
          isFieldFilled(response?.page2?.remitters) &&
          isFieldFilled(response?.page2?.beneficiaries)
        ) {
          startQuestionnaireStep = 2;
        }
        if (
          startQuestionnaireStep >= 2 &&
          isFieldFilled(response?.page3?.marketingDescription) &&
          isFieldFilled(response?.page3?.websiteUrl)
        ) {
          startQuestionnaireStep = 3;
        }
        if (
          startQuestionnaireStep >= 3 &&
          isFieldFilled(response?.page4?.incomingPaymentsPerMonth) &&
          isFieldFilled(response?.page4?.outgoingPaymentsPerMonth) &&
          isFieldFilled(response?.page4?.monthlyIncomingPayments) &&
          isFieldFilled(response?.page4?.monthlyOutgoingPayments)
        ) {
          startQuestionnaireStep = 4;
        }
        if (
          startQuestionnaireStep >= 4 &&
          isFieldFilled(response?.page5?.incomingPaymentCountries) &&
          isFieldFilled(response?.page5?.incomingCurrencies) &&
          isFieldFilled(response?.page5?.outgoingPaymentCountries) &&
          isFieldFilled(response?.page5?.outgoingCurrencies)
        ) {
          startQuestionnaireStep = 5;
        }
        if (
          startQuestionnaireStep >= 5 &&
          isFieldFilled(response?.page6?.requestBusinessCard) &&
          isFieldFilled(response?.page6?.expectedMonthlyCardSpend) &&
          isFieldFilled(response?.page6?.cardholder) &&
          isFieldFilled(response?.page6?.cardholderResidenceCountries)
        ) {
          startQuestionnaireStep = 6;
        }
        if (
          startQuestionnaireStep >= 6 &&
          isFieldFilled(response?.page7?.isOwnedByAnotherCompany)
        ) {
          startQuestionnaireStep = 7;
        }

        if (startQuestionnaireStep === 7) {
          const { personalTaxStatement, bankStatement, proofOfNatureOfBusiness, businessRegistrationCertificate, additionalDocument } = response.page8 || {};
          if (personalTaxStatement) startActiveDocumentStep = Math.max(startActiveDocumentStep, 1);
          if (bankStatement) startActiveDocumentStep = Math.max(startActiveDocumentStep, 1);
          if (proofOfNatureOfBusiness) startActiveDocumentStep = Math.max(startActiveDocumentStep, 2);
          if (businessRegistrationCertificate) startActiveDocumentStep = Math.max(startActiveDocumentStep, 3);
          if (additionalDocument) startActiveDocumentStep = Math.max(startActiveDocumentStep, 4);

          setActiveStep(1);
          setActiveDocumentStep(startActiveDocumentStep);
        } else {
          setActiveStep(startStep);
          setQuestionnaireStep(startQuestionnaireStep);
        }
        const initializeStepper = () => {
          if (!formData?.page8) return;

          const {
            personalTaxStatement,
            bankStatement,
            proofOfNatureOfBusiness,
            businessRegistrationCertificate,
            additionalDocument,
          } = formData.page8;

          let startingStep = 0;

          if (personalTaxStatement && bankStatement) {
            startingStep = 1;
          }
          if (proofOfNatureOfBusiness) {
            startingStep = Math.max(startingStep, 2);
          }
          if (businessRegistrationCertificate) {
            startingStep = Math.max(startingStep, 3);
          }
          if (additionalDocument) {
            startingStep = Math.max(startingStep, 4);
          }

          setActiveDocumentStep((prevStep) => (prevStep !== startingStep ? startingStep : prevStep));
        };

        initializeStepper();
      } catch (error) {
        console.error("Error fetching company info:", error);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      loadCompanyInfo();
    }
  }, [dispatch,
    userId,
    callInfo,
    refreshData,
    formData?.page8?.personalTaxStatement,
    formData?.page8?.bankStatement,
    formData?.page8?.proofOfNatureOfBusiness,
    formData?.page8?.businessRegistrationCertificate,
    formData?.page8?.additionalDocument,
  ]);


  const handleSubmitSection1 = async () => {
    setIsLoading(true)

    const section1Data = {
      companyType: formData.page1.companyType,
      companyName: formData.page1.companyName,
      businessRegistrationNumber: formData.page1.businessRegistrationNumber,
      countryOfIncorporation: formData.page1.countryOfIncorporation,
      businessRegistryLink: formData.page1.businessRegistryLink,
      businessDescription: formData.page1.businessDescription,
      expectedAnnualRevenue: formData.page1.expectedAnnualRevenue,
      numberOfEmployees: formData.page1.numberOfEmployees,
    };


    try {
      const existingData = await dispatch(fetchUserInfo(userId)).unwrap();
      const mergedData = {
        ...existingData,
        page1: {
          ...existingData.page1,
          ...section1Data,
        },
      };

      const section1Payload = {
        ...section1Data,
      };
      dispatch(setDocumentSubmitFormData(mergedData));
      const response = await dispatch(submitSection1(section1Payload));

      if (response.meta.requestStatus === 'fulfilled') {
        setIsLoading(false);
        setCallInfo(!callInfo);
        return true;
      } else {
        setIsLoading(false);
        setCallInfo(!callInfo);
        return false;
      }
    } catch (error) {
      console.error("Error in handleSubmitSection1:", error);
      setIsLoading(false);
      return false;
    }
  };

  const handleSubmitSection2 = async () => {
    setIsLoading(true);
    const section2Data = {
      remitters: formData.page2.remitters,
      beneficiaries: formData.page2.beneficiaries,
    };
    try {
      const response = await dispatch(submitSection2(section2Data));
      if (response.meta.requestStatus === 'fulfilled') {
        setIsLoading(false);
        setCallInfo(!callInfo)
        return true;
      } else {
        setIsLoading(false);
        setCallInfo(!callInfo)
        return false;
      }
    } catch (error) {
      console.error("Error in handleSubmitSection2:", error);
      setIsLoading(false);
      return false;
    }
  };

  const handleSubmitSection3 = async () => {
    setIsLoading(true);
    const section3Data = {
      marketingDescription: formData.page3.marketingDescription,
      websiteUrl: formData.page3.websiteUrl,
      facebookPage: formData.page3.facebookPage,
      instagramName: formData.page3.instagramName,
    };
    try {
      const response = await dispatch(submitSection3(section3Data));
      if (response.meta.requestStatus === 'fulfilled') {
        setIsLoading(false);
        setCallInfo(!callInfo)
        return true;
      } else {
        setIsLoading(false);
        setCallInfo(!callInfo)
        return false;
      }
    } catch (error) {
      console.error("Error in handleSubmitSection3:", error);
      setIsLoading(false);
      return false;
    }
  };

  const handleSubmitSection4 = async () => {
    setIsLoading(true);
    const section4Data = {
      incomingPaymentsPerMonth: formData.page4.incomingPaymentsPerMonth,
      outgoingPaymentsPerMonth: formData.page4.outgoingPaymentsPerMonth,
      monthlyIncomingPayments: formData.page4.monthlyIncomingPayments,
      monthlyOutgoingPayments: formData.page4.monthlyOutgoingPayments,
    };
    try {
      const response = await dispatch(submitSection4(section4Data));
      if (response.meta.requestStatus === 'fulfilled') {
        setIsLoading(false);
        setCallInfo(!callInfo);
        return true;
      } else {
        setIsLoading(false);
        setCallInfo(!callInfo);
        return false;
      }
    } catch (error) {
      console.error("Error in handleSubmitSection4:", error);
      setIsLoading(false);
      return false;
    }
  };

  const handleSubmitSection5 = async () => {
    setIsLoading(true);

    const owningCompanyDetails = formData.page7.isOwnedByAnotherCompany
      ? formData.page7.owningCompanyDetails || {
        companyName: "",
        countryOfRegistration: "",
        businessRegistrationNumber: "",
      }
      : {} as { companyName: string; countryOfRegistration: string; businessRegistrationNumber: string };

    let section5Data = {}

    if (formData.page1.companyType === "Sole Trader" || formData.page1.companyType === "Enkeltmandsvirksomhed") {
      section5Data = {
        beneficialOwners: beneficialOwners,
        hasMultipleOwners: formData.page7.hasMultipleOwners,
        isOwnedByAnotherCompany: formData.page7.isOwnedByAnotherCompany,
        owningCompanyDetails: owningCompanyDetails,
      };
    }
    else if (formData.page7.isUboSameAsDirector === true) {
      section5Data = {
        isUboSameAsDirector: formData.page7.isUboSameAsDirector,
        beneficialOwners: beneficialOwners,
        hasMultipleOwners: formData.page7.hasMultipleOwners,
        isOwnedByAnotherCompany: formData.page7.isOwnedByAnotherCompany,
        owningCompanyDetails: owningCompanyDetails,
      };
    } else {
      section5Data = {
        directors: directors,
        beneficialOwners: beneficialOwners,
        isDirectorSoleOwner: formData.page7.isDirectorSoleOwner,
        isUboSameAsDirector: formData.page7.isUboSameAsDirector,
        hasMultipleOwners: formData.page7.hasMultipleOwners,
        isOwnedByAnotherCompany: formData.page7.isOwnedByAnotherCompany,
        owningCompanyDetails: owningCompanyDetails,
      };
    }

    try {
      const response = await dispatch(submitSection5(section5Data));
      if (response.meta.requestStatus === "fulfilled") {
        setIsLoading(false);
        setCallInfo(!callInfo);
        return true;
      } else {
        setIsLoading(false);
        setCallInfo(!callInfo);
        return false;
      }
    } catch (error) {
      console.error("Error in handleSubmitSection5:", error);
      setIsLoading(false);
      return false;
    }
  };

  const handleSubmitSection6 = async () => {
    setIsLoading(true);
    const section6Data = {
      incomingPaymentCountries: formData.page5.incomingPaymentCountries,
      outgoingPaymentCountries: formData.page5.outgoingPaymentCountries,
      incomingCurrencies: formData.page5.incomingCurrencies,
      outgoingCurrencies: formData.page5.outgoingCurrencies,
    };
    try {
      const response = await dispatch(submitSection6(section6Data));
      if (response.meta.requestStatus === 'fulfilled') {
        setIsLoading(false);
        setCallInfo(!callInfo);
        return true;
      } else {
        setIsLoading(false);
        setCallInfo(!callInfo);
        return false;
      }
    } catch (error) {
      console.error("Error in handleSubmitSection6:", error);
      setIsLoading(false);
      return false;
    }
  };

  const handleSubmitSection7 = async () => {
    setIsLoading(true);
    const section7Data = {
      requestBusinessCard: formData.page6.requestBusinessCard,
      expectedMonthlyCardSpend: formData.page6.expectedMonthlyCardSpend,
      cardholder: formData.page6.cardholder,
      cardholderResidenceCountries: formData.page6.cardholderResidenceCountries,
    };
    try {
      const response = await dispatch(submitSection7(section7Data));
      if (response.meta.requestStatus === 'fulfilled') {
        setIsLoading(false);
        setCallInfo(!callInfo);
        return true;
      } else {
        setIsLoading(false);
        setCallInfo(!callInfo);
        return false;
      }
    } catch (error) {
      console.error("Error in handleSubmitSection7:", error);
      setIsLoading(false);
      return false;
    }
  };
  const handleUploadDocuments = async () => {
    setIsLoading(true);
    const formPayload = new FormData();

    if (activeDocumentStep === 0) {
      if (!personalTaxStatement || !bankStatement) {
        Swal.fire({
          title: 'Error!',
          text: 'Please upload both Personal Tax Statement and Bank Statement.',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
        setCallInfo(!callInfo);
        setIsLoading(false);
        return;
      }
      formPayload.append("personalTaxStatement", personalTaxStatement);
      formPayload.append("bankStatement", bankStatement);
      setCallInfo(!callInfo);
    } else if (activeDocumentStep === 1) {
      if (!proofNatureBusinessFile) {
        Swal.fire({
          title: 'Error!',
          text: 'Please upload Proof of Nature of Business.',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
        setCallInfo(!callInfo);
        setIsLoading(false);
        return;
      }
      formPayload.append("proofOfNatureOfBusiness", proofNatureBusinessFile);
      setCallInfo(!callInfo);
    } else if (activeDocumentStep === 2) {
      if (!businessRegCertificateFile) {
        Swal.fire({
          title: 'Error!',
          text: 'Please upload Business Registration Certificate.',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
        setCallInfo(!callInfo);
        setIsLoading(false);
        return;
      }
      formPayload.append("businessRegistrationCertificate", businessRegCertificateFile);
      setCallInfo(!callInfo);
    } else if (activeDocumentStep === 3) {
      if (additionalDocuments.length === 0) {
        Swal.fire({
          title: 'Error!',
          text: 'Please upload at least one additional document.',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
        setCallInfo(!callInfo);
        setIsLoading(false);
        return;
      }

      additionalDocuments.forEach((file) => {
        formPayload.append('additionalDocument', file);
      });
      setCallInfo(!callInfo);
    }
    try {
      const documentsUploadedPreviously = formData?.page8;
      const isFirstUpload = !documentsUploadedPreviously.personalTaxStatement && !documentsUploadedPreviously.bankStatement;

      const response = documentsUploadedPreviously.isSubmitted
        ? await dispatch(updateDocuments(formPayload)).unwrap()
        : await dispatch(submitDocuments(formPayload)).unwrap();

      if (response) {
        Swal.fire({
          title: 'Success!',
          text: `Documents ${isFirstUpload ? 'submitted' : 'updated'} successfully.`,
          icon: 'success',
          confirmButtonText: 'Ok',
        });
        setRefreshData(!refreshData);

        if (isFirstUpload) {
          setCallUpdateForDocument(true);
        }

        if (activeDocumentStep < documentSteps.length - 1) {
          setActiveDocumentStep((prevStep) => prevStep + 1);
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
    } catch (error) {
      console.error("Error uploading documents:", error);
      setCallInfo(!callInfo);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdditionalDocumentsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      setAdditionalDocuments((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };
  const handleRemoveAdditionalDocument = (index: number) => {
    setAdditionalDocuments((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const documentSteps = [
    {
      label: 'OnBoarding.sourceOfWealth',
      description: "",
      additionalText: 'OnBoarding.descrip1',
    },
    {
      label: 'OnBoarding.proofOfNatureOfBusiness',
      additionalText: 'OnBoarding.descrip2',
    },
    {
      label: 'OnBoarding.businessRegistrationCertificate',
      description: "",
      additionalText: 'OnBoarding.descrip3',
    },
    {
      label: 'Additional Documents',
      description: "",
      additionalText: 'Please provide any Additional Documents required for the Onboarding.',
    },
  ];
  const getNestedValue = (obj: any, path: string) => path.split('.').reduce((acc, part) => acc && acc[part], obj);

  const handleSaveClick = async (fieldPath: string) => {
    setIsLoading(true)
    const value = getNestedValue(formData, fieldPath);
    const field = fieldPath.split('.').pop();

    if (!editableField || !value || !field) return;

    try {
      await dispatch(updateCompanyInfoField({
        userId,
        field,
        value: typeof value === 'string' ? value : JSON.stringify(value),
      }));

      if (editableField === 'page1.businessRegistrationNumber' && typeof value === 'string') {
        await dispatch(fetchCompanyInfoByCVR(value));
      }
      setQuestionnaireStep(questionnaireStep + 1)
      setEditableField(null);
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error("Error saving the field:", error);
    }
  };


  const handleCancelClick = (fieldPath: string) => {
    if (editableField) {
      dispatch(setDocumentSubmitFormData({ ...formData, [fieldPath]: fieldBackup }));
      setEditableField(null);
    }
  };

  const handleEditClick = (field: string, currentValue: string) => {
    setEditableField(field);
    setFieldBackup(currentValue);
  };

  const steps = [
    'OnBoarding.gatherInformation',
    'OnBoarding.documentSubmission'
  ];

  const isStepOptional = (step: number) => {
    return false;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const saveToLocalStorage = (data: DocumentSubmitFormData, step: number, questionnaireStep: number) => {
    localStorage.setItem(FORM_DATA_KEY, JSON.stringify(data));
    localStorage.setItem(STEP_KEY, JSON.stringify(step));
    localStorage.setItem('questionnaireStep', JSON.stringify(questionnaireStep));
  };
  const setNestedField = (obj: any, path: string, newValue: any, idx?: number) => {
    const parts = path.split(".");
    const last = parts.pop()!;
    const target = parts.reduce((acc, part) => {
      if (!acc[part]) acc[part] = {};
      return acc[part];
    }, obj);

    if (Array.isArray(target[last]) && idx !== undefined) {
      target[last][idx] = newValue;
    } else {
      target[last] = newValue;
    }
  };

  const handleInputChange = (fieldPath: string, value: any, index?: number) => {
    const updatedFormData = JSON.parse(JSON.stringify(formData));

    setNestedField(updatedFormData, fieldPath, value, index);
    dispatch(setDocumentSubmitFormData(updatedFormData));
  };
  const handleNext = async () => {
    const validationErrors = validateFields(questionnaireStep);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) return;

    let isFulfilled = false;

    if (activeStep === 0) {
      if (questionnaireStep === 0) {
        isFulfilled = await handleSubmitSection1();
      } else if (questionnaireStep === 1) {
        isFulfilled = await handleSubmitSection2();
      } else if (questionnaireStep === 2) {
        isFulfilled = await handleSubmitSection3();
      } else if (questionnaireStep === 3) {
        isFulfilled = await handleSubmitSection4();
      } else if (questionnaireStep === 4) {
        isFulfilled = await handleSubmitSection6();
      } else if (questionnaireStep === 5) {
        isFulfilled = await handleSubmitSection7();
      } else if (questionnaireStep === 6) {
        isFulfilled = await handleSubmitSection5();
      }

      if (isFulfilled) {
        if (questionnaireStep < 6) {
          setQuestionnaireStep((prev) => prev + 1);
          setProgressValue((prev) => prev + 14.29);
          saveToLocalStorage(formData, activeDocumentStep, questionnaireStep + 1);
        } else if (questionnaireStep === 6) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setQuestionnaireStep(0);
        }
      }
    } else if (activeStep === 1) {
      if (activeDocumentStep < documentSteps.length - 1) {
        setActiveDocumentStep((prevStep) => prevStep + 1);
        saveToLocalStorage(formData, activeDocumentStep + 1, questionnaireStep);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };
  const handleDeleteDirector = (index: number) => {
    if (index === 0) {
      alert("The first director cannot be deleted.");
      return;
    }

    const updatedDirectors = [...directors];
    updatedDirectors.splice(index, 1);
    setDirectors(updatedDirectors);

    dispatch(deleteDirector(index));
  };

  const handleDeleteBeneficialOwner = (index: number) => {
    const updatedBeneficialOwners = [...beneficialOwners];
    updatedBeneficialOwners.splice(index, 1);
    setBeneficialOwners(updatedBeneficialOwners);
  };


  const handleBack = () => {
    if (activeStep === 0) {
      if (questionnaireStep > 0) {
        setQuestionnaireStep((prev) => prev - 1);
        setProgressValue(progressValue - 20);
      }
    } else if (activeStep === 1) {
      if (activeDocumentStep > 0) {
        setActiveDocumentStep((prevActiveDocumentStep) => prevActiveDocumentStep - 1);
        saveToLocalStorage(formData, activeDocumentStep - 1, questionnaireStep);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
    }
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 20,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 10,
      backgroundColor: theme.palette.mode === 'dark' ? '#006FF4' : '#006FF4',
    },
  }));


  const fetchCompanyInfo = async (registrationNumber: string, userEnteredRegistrationNumber: string) => {
    setCvrLoading(true);

    const mapCVRDataToFormData = (cvrData: {
      company_name: any;
      vat: any;
      links: any;
      industrydesc: any;
      accounts: any;
      CompanySecondaryName: any;
      EstablishmentOfOwnership: any;
      Country: any;
      company_number: any;
    }) => {
      return {
        page1: {
          ...formData.page1,
          companyName: cvrData.company_name || formData.page1.companyName,
          businessRegistryLink: cvrData.links.filing_history || formData.page1.businessRegistryLink,
          businessDescription: cvrData.CompanySecondaryName || formData.page1.businessDescription,
          numberOfEmployees: cvrData.accounts.accounting_reference_date.day || formData.page1.numberOfEmployees,
          businessRegistrationNumber: cvrData.company_number || formData.page1.businessRegistrationNumber,
        },
      };
    };

    try {
      const response = await dispatch(fetchCompanyInfoByCVR(registrationNumber)).unwrap();
      // const mappedData = mapCVRDataToFormData(response.data.Result.Results[0]);
      const mappedData = mapCVRDataToFormData(response.data);

      dispatch(setDocumentSubmitFormData(mappedData));
      setCvrLoading(false);
    } catch (error) {
      console.error("Error fetching company info:", error);
      setCvrLoading(false);
    }
  };

  const debouncedFetchCompanyInfo = useCallback(
    debounce((registrationNumber, userEnteredRegistrationNumber) => {
      if (registrationNumber) {
        fetchCompanyInfo(registrationNumber, userEnteredRegistrationNumber);
      }
    }, 500),
    []
  );

  const handleCompanyRegistrationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCompanyRegistrationNumber(value);

    dispatch(
      setDocumentSubmitFormData({
        ...formData,
        page1: {
          ...formData.page1,
          businessRegistrationNumber: value,
        },
      })
    );

    debouncedFetchCompanyInfo(value, value);
  };


  const renderEditableField = (label: string, field: string, value: string) => {
    const isEditableInitially = initialFormData && getNestedValue(initialFormData, field);
    const isEditingEnabled = editableField === field || !isEditableInitially;
    setEditFieldName(field)
    if (field === 'page1.businessRegistrationNumber') {
      return (
        <div className="inputFlex">
          <div style={{ display: "flex", gap: 4, alignItems: "center", justifyContent: "space-between", color: "#006ff4" }}>
            <p className="inputLabel">{t(label)}</p>
            {isEditableInitially && editableField !== field ? (
              <span style={{ cursor: 'pointer' }} onClick={() => handleEditClick(field, value)}>
                <img src={EditIcon} alt="Edit" width={14} height={14} />
                <span>Edit</span>
              </span>
            ) : null}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '100%' }}>
              <InputTypeText
                className="inputText"
                value={value}
                onChange={handleCompanyRegistrationChange}
                disabled={!isEditingEnabled}
              />
            </div>
            {
              cvrLoading ?
                <div style={{ marginLeft: 10 }}>
                  <CircularProgress />
                </div>
                :
                null
            }
          </div>
        </div>
      );
    }
    return (
      <div className="inputFlex">
        <div style={{ display: "flex", gap: 4, alignItems: "center", justifyContent: "space-between", color: "#006ff4" }}>
          <p className="inputLabel">{t(label)}</p>
          {isEditableInitially && editableField !== field ? (
            <span style={{ cursor: 'pointer' }} onClick={() => handleEditClick(field, value)}>
              <img src={EditIcon} alt="Edit" width={14} height={14} />
              <span>Edit</span>
            </span>
          ) : null}
        </div>

        <InputTypeText
          className="inputText"
          value={value}
          onChange={(e) => handleInputChange(field, e.target.value)}
          disabled={!isEditingEnabled}
        />
      </div>
    );
  };


  const renderQuestionnaireContent = () => {
    switch (questionnaireStep) {
      case 0:
        return (
          <Section1
            formData={formData}
            errors={errors}
            handleInputChange={handleInputChange}
            renderEditableField={renderEditableField}
          />
        );
      case 1:
        return (
          <Section2
            formData={formData}
            errors={errors}
            handleInputChange={handleInputChange}
            renderEditableField={renderEditableField}
          />
        );
      case 2:
        return (
          <Section3
            formData={formData}
            errors={errors}
            handleInputChange={handleInputChange}
          />
        );

      case 3:
        return (
          <Section4
            formData={formData}
            errors={errors}
            handleInputChange={handleInputChange}
          />
        );

      case 4:
        return (
          <Section6
            formData={formData}
            errors={errors}
            handleInputChange={handleInputChange}
          />
        );
      case 5:
        return (
          <Section7
            formData={formData}
            errors={errors}
            handleInputChange={handleInputChange}
          />
        )
      case 6:
        return (
          <Section5Page
            formData={formData}
            errors={errors}
            directors={directors}
            handleAddDirector={handleAddDirector}
            handleDeleteDirector={handleDeleteDirector}
            handleDirectorInputChange={handleDirectorInputChange}
            beneficialOwners={beneficialOwners}
            handleAddBeneficialOwner={handleAddBeneficialOwner}
            handleDeleteBeneficialOwner={handleDeleteBeneficialOwner}
            handleBeneficialOwnerInputChange={handleBeneficialOwnerInputChange}
            handleInputChange={handleInputChange}
            loading={isLoading}
          />
        )
      default:
        return null;
    }
  };

  const getTitleForQuestionnaireStep = (step: number) => {
    switch (step) {
      case 0:
      case 1:
        return 'OnBoarding.companyInformation';
      case 2:
        return 'OnBoarding.Marketing';
      case 3:
        return 'OnBoarding.Transactions';
      case 4:
        return 'OnBoarding.Payments';
      case 5:
        return 'OnBoarding.Business Card';
      case 6:
        return 'OnBoarding.ownersAndDirectors';
      default:
        return 'OnBoarding.companyInformation';
    }
  };

  const renderContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <>
            <div className='mainTitle'>
              <div className='mainTitle'>
                <h2 className="onBoarding-title">
                  {t(getTitleForQuestionnaireStep(questionnaireStep))}
                </h2>
                <div style={{ marginBottom: 20 }}>
                  <h2 style={{ color: '#006FF4', fontSize: '24px', fontWeight: 700 }}>
                    {formData.onboardingProgress}% {t('OnBoarding.complete')}
                  </h2>
                  <div style={{ marginBottom: 20 }}>
                    <BorderLinearProgress variant="determinate" value={formData.onboardingProgress} />
                  </div>
                  <div style={{ padding: 10, fontSize: 18, backgroundColor: '#006FF4', color: 'white', borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <p style={{ marginBottom: 0 }}>
                      {t('OnBoarding.callForHelp.part1')}
                      <span style={{ textDecoration: 'underline', fontWeight: 500 }}>{" +45 12345678 "}</span>
                      {t('OnBoarding.callForHelp.part2')}
                    </p>
                  </div>
                </div>
              </div>
              {
                loading ?
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: "50px 0px 50px 0px" }}>
                    <CircularProgress />
                  </div>
                  :
                  <div>
                    {renderQuestionnaireContent()}
                  </div>
              }

              <Box sx={{ display: 'flex', pt: 2, gap: 2 }}>
                {questionnaireStep > 0 && <Button className='backButton' onClick={handleBack}>{t('resetPassword.back')}</Button>}
                {
                  editableField ?
                    <BasicButton isLoading={isLoading} text={t('paymentReceiver.editBeneficiaryModal.button')} type='button' onClick={() => handleSaveClick(editFieldName)} />
                    :
                    <>
                      {questionnaireStep < 7 && <BasicButton isLoading={isLoading} type='button' onClick={handleNext} text={t('pagination.next')} />}
                    </>
                }
              </Box>
            </div>

          </>
        );
      case 1:
        return (
          <>
            {/* {
              loading ?
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: "50px 0px 50px 0px" }}>
                  <CircularProgress />
                </div>
                : */}

            <>
              <form>
                <div className='mainTitle'>
                  <h2 style={{ fontWeight: 700 }}>{t('OnBoarding.submitAdditionalDocuments')}</h2>
                  <p>{t('OnBoarding.uploadDocumentsDescription')}</p>
                </div>
                <div>
                  <Stepper activeStep={activeDocumentStep} orientation="vertical">
                    {documentSteps.map((step, index) => (
                      <Step key={step.label}>
                        <StepLabel>
                          <Typography style={{ fontFamily: 'Montserrat, sans-serif', fontWeight: 600, fontSize: 18 }}>
                            {t(step.label)}
                          </Typography>
                        </StepLabel>
                        {step.additionalText && (
                          <Typography variant="caption" sx={{ fontFamily: 'Montserrat, sans-serif', ml: 3, fontSize: 14 }}>
                            {t(step.additionalText)}
                          </Typography>
                        )}
                        <StepContent>
                          {step.description && (
                            <Typography className='textFont'>{step.description}</Typography>
                          )}
                          {index === 0 && (
                            <div>
                              <DocumentUpload
                                label={t("OnBoarding.uploadFileLabel")}
                                instructions={t("OnBoarding.uploadFileInstructions") as string}
                                file={personalTaxStatement}
                                buttonText={t("OnBoarding.chooseFile")}
                                inputId="personalTaxStatement"
                                onFileChange={(e) => setPersonalTaxStatement(e.target.files?.[0] || null)}
                                onButtonClick={() => document.getElementById("personalTaxStatement")?.click()}
                                fileUploadIcon={fileUpload}
                                loading={isLoading}
                              />

                              <DocumentUpload
                                label={t("OnBoarding.uploadFileLabel")}
                                instructions={t("OnBoarding.uploadFileInstructions") as string}
                                file={bankStatement}
                                buttonText={t("OnBoarding.chooseFile")}
                                inputId="bankStatement"
                                onFileChange={(e) => setBankStatement(e.target.files?.[0] || null)}
                                onButtonClick={() => document.getElementById("bankStatement")?.click()}
                                fileUploadIcon={fileUpload}
                                loading={isLoading}
                              />
                            </div>
                          )}
                          {index === 1 && (
                            <div>
                              <DocumentUpload
                                label={t("OnBoarding.uploadFileLabel")}
                                instructions={t("OnBoarding.uploadFileInstructions") as string}
                                file={proofNatureBusinessFile}
                                buttonText={t("OnBoarding.chooseFile")}
                                inputId="proofOfNatureOfBusiness"
                                onFileChange={(e) => setProofNatureBusinessFile(e.target.files?.[0] || null)}
                                onButtonClick={() => document.getElementById("proofOfNatureOfBusiness")?.click()}
                                fileUploadIcon={fileUpload}
                                loading={isLoading}
                              />
                            </div>
                          )}
                          {index === 2 && (
                            <div>
                              <DocumentUpload
                                label={t("OnBoarding.uploadFileLabel")}
                                instructions={t("OnBoarding.uploadFileInstructions") as string}
                                file={businessRegCertificateFile}
                                buttonText={t("OnBoarding.chooseFile")}
                                inputId="businessRegistrationCertificate"
                                onFileChange={(e) => setBusinessRegCertificateFile(e.target.files?.[0] || null)}
                                onButtonClick={() => document.getElementById("businessRegistrationCertificate")?.click()}
                                fileUploadIcon={fileUpload}
                                loading={isLoading}
                              />
                            </div>
                          )}
                          {index === 3 && (
                            <div>
                              <DocumentUpload
                                label={t("OnBoarding.uploadFileLabel")}
                                instructions={t("Upload your additional documents here!") as string}
                                file={additionalDocuments} // Now supports multiple files
                                buttonText={t("OnBoarding.chooseFile")}
                                inputId="additionalDocuments"
                                onFileChange={handleAdditionalDocumentsChange}
                                onFileRemove={handleRemoveAdditionalDocument} // New function for file removal
                                onButtonClick={() => document.getElementById("additionalDocuments")?.click()}
                                fileUploadIcon={fileUpload}
                                loading={isLoading}
                              />

                            </div>
                          )}
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                  <div style={{ marginTop: 15, display: 'flex', gap: 15 }}>
                    <Button className='backButton' type="button" onClick={handleBack}>{t("Back")}</Button>
                    <BasicButton
                      isLoading={isLoading}
                      type="button"
                      onClick={handleUploadDocuments}
                      text={t("Upload and Procced")}
                    />
                  </div>
                  <p onClick={() => navigate('/verification')} className='skipText'>{t('OnBoarding.skipforNow')}</p>
                </div>

              </form>
            </>
            {/* } */}
          </>
        );
      default:
        return <p></p>;
    }
  };

  return (
    <div className='questionaire'>
      <div className="onBoardingImage onBoardingBGImg" style={{ margin: "5px 0 0px 5px" }}>
        <img className="omwLogo" src={OMWLogoWhite} style={{ height: '100vh' }} />
      </div>
      <div className="onBoardingForm verificationForm d-flex flex-column justify-content-start ">
        <div className="lng-dropdown">
          <LanguageDropdown />
        </div>
        <Box sx={{ width: '100%', marginTop: "60px" }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: { optional?: React.ReactNode } = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}><Typography style={{ fontFamily: 'Montserrat, sans-serif' }}>{t(label)}</Typography></StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div style={{ height: '84vh', overflowY: 'scroll' }}>
                {renderContent()}
              </div>
            </React.Fragment>
          )}
        </Box>
      </div>
    </div>
  );
}

export default withTranslation()(DocumentSubmit);