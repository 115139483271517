
export const regexStrConst = {
  //Required format: johndoe@mail.com
  emailRegex: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z]+$/,
  // eslint-disable-next-line no-useless-escape
  phoneNumberRegex: /^\+?[0-9]{1,15}([-.\s]?[0-9]+)*$/,
  //Requirements: min. char. limit = 8, upper and lower case alphanumeric
  // eslint-disable-next-line no-useless-escape
  passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{7,})/,
  amountRegex: /^(^\d*\.?\d*[1-9]+\d*$)|(^[1-9]+\.?\d*$)/,

};
export const RE_DIGIT = new RegExp(/^\d+$/);
export const errorMsgs = {
  emptyEmail: "Please enter valid email",
  emptyIdentity: "Please enter valid verification code",
  emptyInput: "Please enter valid email or phone number",
  emptyPasswordReg:
    "Password must be 8 characters long including capital letters, small letters, special character and number",
  emptyPassword: "Password does not meet the requirements",
  confirmPassword: "Password should match",
  incorrectEmail: "Email not valid",
  incorrectPassword: "Password does not match the requirements",
  emptyAmount: "Please enter valid amount",
  greaterThanFiveAmount: "Please enter amount greater than 5",
  emptyPaymentRefError: "Payment reference required",
  emptyCurrency: "Please enter currency",
  emptyBenCurrency: "Please enter currency",
  emptyName: "Please enter name",
  emptyFullName: "Please enter full name",
  emptyCompanyName: "Please enter company name",
  emptyPhone: "Please enter valid phone number",
  emptyAccount: "Please enter account number",
  selectAccount: "Please select account",
  emptyNewField: "This field cannot be empty",
  emptyAddress: "Please enter address",
  emptyCity: "Please enter city",
  emptyPostCode: "Please enter postal code",
  emptyCountry: "Please enter country",
  emptySortCode: "Please enter sort code",
  emptyRoutingNumber: "Please enter routing number",
  emptySwiftCOde: "Please enter Swift Code",
  emptyBeneficiary: "Please select recipient",
  emptyDescription: "Please enter comments",
  emptyReasonDescription: "Please enter description",
  emptyCompanyRegNo: "Please enter Company Regsitration Number",
  emptyLogo:"Please upload the company logo",
  emptyFao: "Please enter the P.O. Box name",
  // Invoice error messages
  emptyAccountType: "Please select invoice type",
  emptyDate: "Please select a date",
  emptyAddreesee: "Please enter name",
  emptyStreetAddress: "Please enter street address",
  emptyZipCode: "Please enter zipcode",
  emptyCoAddress: "Please enter CoAddress",
  emptyCountryCode: "Please enter Country Code",
  emptyIRCode: "Please enter invoice reason code",
  emptyIRDes: "Please enter description",
  emptyPayableAmount: "Please enter amount payable",
  emptyItemDes: "Please enter item description",
  emptyItemAmount: "Please enter item amount",
  smallDateRange: "Your enddate is earlier than the start date",
  emptyRate: "Please enter rate amount",
  emptyItemList: "Please add atlest one item",
  itemListFieldsRequired: "All fields required for item list",
  registrationNumberRequired: "Please enter company registration number",
  selectRecipient: "Please select recipient",
  emptyInvoiceType: "Please Select Invoice Type",
// DocumentSubmit Errors
  companyNameErr: "Please enter company name",
  companyRegistrationNumberErr: "Please enter company registration No.",
  countryOfIncorporationErr: "Please enter country of incorporation",
  businessDescriptionErr: "Please enter business description",
  averageAnnualRevenueEURErr: "Please enter average annual revenue in EUR",
  numberOfEmployeesErr: "Please enter number of employees",
  industryErr: "Please select industry",
  companyOperationsErr: "Please enter company operations",
  companyAddressErr: "Please enter company address",
  websiteLinkErr: "Please enter website link",
  
  countriesSendingMoneyToErr: "Please enter country you send money to",
  estimatedAnnualNumberOfPaymentsErr: "Please enter estimated annual number of payments",
  incomingCurrenciesErr: "Please enter incoming currencies",
  outgoingCurrenciesErr: "Please enter outgoing currencies",
  monthlyNumberOfTransactionsErr: "Please enter monthly number of transactions",
  intrestedInDebitCardErr: "Please specify if you're interested in a debit card",
  top5CustomersErr: "Please enter top 5 customers",
  top5SuppliersErr: "Please enter top 5 suppliers",
  purposeOfOpeningBusinessAccountErr: "Please enter purpose of opening business account",
  countriesPayingTaxesToErr: "Please enter countries where you pay taxes",
  top3RemittersErr: "Please enter top 3 remitters",
  top3BeneficiariesErr: "Please enter top 3 beneficiaries",
  
  directorInformationErr: "Please enter director information",
  isSoleOwnerErr: "Please specify if you are the sole owner",
  shareHoldersNameErr: "Please enter shareholder's name",
  registrationNumberErr: "Please enter registration number",
  percentagesErr: "Please enter percentages",
  proofAddressFileErr: "Please upload proof of address file",
  passportFileErr: "Please upload passport file",
  proofOfBusinessFileErr: "Please upload proof of business file"
};
export const Industries = [
  {
    value: "Industry1",
    item: "Industry1",
  },
  {
    value: "Industry2",
    item: "Industry2",
  },
  {
    value: "Industry3",
    item: "Industry3",
  },
]

export const Currencies = [
  {
    value: "AED",
    item: "AED",
  },
  {
    value: "AUD",
    item: "AUD",
  },
  {
    value: "CAD",
    item: "CAD",
  },
  {
    value: "CHF",
    item: "CHF",
  },
  {
    value: "CNH",
    item: "CNH",
  },
  {
    value: "CZK",
    item: "CZK",
  },
  {
    value: "DKK",
    item: "DKK",
  },
  {
    value: "EUR",
    item: "EUR",
  },
  {
    value: "GBP",
    item: "GBP",
  },
  {
    value: "HKD",
    item: "HKD",
  },
  {
    value: "HRK",
    item: "HRK",
  },
  {
    value: "HUF",
    item: "HUF",
  },
  {
    value: "ILS",
    item: "ILS",
  },
  {
    value: "JPY",
    item: "JPY",
  },
  {
    value: "MXN",
    item: "MXN",
  },
  {
    value: "NOK",
    item: "NOK",
  },
  {
    value: "NZD",
    item: "NZD",
  },
  {
    value: "PLN",
    item: "PLN",
  },
  {
    value: "RON",
    item: "RON",
  },
  {
    value: "SAR",
    item: "SAR",
  },
  {
    value: "SEK",
    item: "SEK",
  },
  {
    value: "SGD",
    item: "SGD",
  },
  {
    value: "TRY",
    item: "TRY",
  },
  {
    value: "USD",
    item: "USD",
  },
];

export const OptionSelect = [
  {
    value: "YES",
    item: "YES",
  },
  {
    value: "NO",
    item: "NO",
  },
]

export const LOGO_URL = 'https://bkimage.onemoneyway.com/omw-logo.png';
//export const CRISP_ID = '3f3fff46-2cd1-4a65-bcd5-3ca35470491e'//test
export const CRISP_ID = '1e80f393-0a2d-4526-b5cc-4fc6fbe8323c';//prod

