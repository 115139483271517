// Section1.tsx
import React, { useEffect } from 'react';
import InputTypeText from '../../components/shared/Input/Basic/InputTypeText';
import { DocumentSubmitFormData } from '../../redux/toolkit/documentSubmit/interface';
import { Autocomplete, TextField, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useTranslation, withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { fetchCurrencies } from '../../redux/toolkit/documentSubmit/reducer';
import { Information } from '../../constants/Images/index';

type Section1Props = {
  formData: DocumentSubmitFormData;
  errors: { [key: string]: string };
  handleInputChange: (fieldPath: string, value: any) => void;
  renderEditableField: (label: string, field: string, value: string) => JSX.Element;
};

const Section1: React.FC<Section1Props> = ({ formData, errors, handleInputChange, renderEditableField }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { currencies } = useSelector((state: RootState) => state.currencies);
  const validCompanyTypes = {
    "United Kingdom": ["Sole Trader", "Limited Company"],
    default: [
      "Enkeltmandsvirksomhed",
      "Interessentskab (I/S)",
      "Aktieselskab (A/S)",
      "Anpartsselskab (ApS)",
    ],
  };

  useEffect(() => {
    dispatch(fetchCurrencies());
  }, [dispatch]);

  const selectedCountry = (formData?.page1?.countryOfIncorporation || "default") as keyof typeof validCompanyTypes;
  const companyTypeOptions = validCompanyTypes[selectedCountry] || validCompanyTypes.default;

  const questions = t('OnBoarding.questionList', { returnObjects: true }) as string[];
  const currencyCode = localStorage.getItem('selectedCountryCode') || formData?.page1?.selectedCurrency;

  return (
    <div>
      <div className="inputFlex">
        <p className="inputLabel">{t('OnBoarding.countryOfIncorporation')}</p> <span style={{ color: 'red' }}>*</span>
        <Autocomplete
          options={currencies
            .filter((ele) => ['United Kingdom', 'Denmark', 'Sweden', 'Canada'].includes(ele.country))
            .map((ele) => ele.country)}
          value={formData?.page1?.countryOfIncorporation}
          onChange={(event, newValue) => {
            handleInputChange('page1.countryOfIncorporation', newValue);
            const selectedCountry = currencies.find((ele) => ele.country === newValue);
            if (selectedCountry?.code) {
              localStorage.setItem('selectedCountryCode', selectedCountry.code);
            }
          }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" placeholder='' style={{ borderRadius: 20 }} />
          )}
        />
        {errors.countryOfIncorporation && <p className="error-message">{errors.countryOfIncorporation}</p>}
      </div>

      <div>
        {renderEditableField(t('OnBoarding.companyName'), 'page1.companyName', formData?.page1?.companyName)}
        {errors.companyName && <p className="error-message">{errors.companyName}</p>}
      </div>

      <div>
        {renderEditableField(t('OnBoarding.companyRegistrationNumber'), 'page1.businessRegistrationNumber', formData?.page1?.businessRegistrationNumber)}
        {errors.businessRegistrationNumber && <p className="error-message">{errors.businessRegistrationNumber}</p>}
      </div>

      <div className="inputFlex">
        <p className="inputLabel">{t('OnBoarding.companyType')}</p> <span style={{ color: 'red' }}>*</span>

        <Autocomplete
          options={companyTypeOptions.map((ele) => ele)}
          value={formData?.page1?.companyType}
          onChange={(event, newValue) => {
            handleInputChange('page1.companyType', newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" placeholder='' style={{ borderRadius: 20 }} />
          )}
        />

        {errors.companyType && <p className="error-message">{errors.companyType}</p>}
      </div>

      <div className="inputFlex">
        <p className="inputLabel">{t('OnBoarding.businessRegistryLink')}</p> <span style={{ color: 'red' }}>*</span>
        <InputTypeText
          className="inputText"
          placeholder=""
          value={formData?.page1?.businessRegistryLink}
          onChange={(e) => handleInputChange('page1.businessRegistryLink', e.target.value)}
        />
        {errors.businessRegistryLink && <p className="error-message">{errors.businessRegistryLink}</p>}
      </div>

      <div className="inputFlex">
        <p className="inputLabel">{t('OnBoarding.businessDescription')}</p> <span style={{ color: 'red' }}>*</span>
        <div style={{ display: 'flex', gap: 14, marginTop: 10 }}>
          <img src={Information} alt="icon" width={16} height={16} style={{ marginTop: 5 }} />
          <div>
            <p>{t('OnBoarding.detailedDescription')}</p>
            <p>{t('OnBoarding.descriptionQuestions')}</p>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              {questions.map((question, index) => (
                <li key={index} style={{ marginBottom: '5px', fontSize: '14px' }}>
                  {question}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <textarea
          className="textarea_style"
          value={formData?.page1?.businessDescription}
          onChange={(e) => handleInputChange('page1.businessDescription', e.target.value)}
        />
        {errors.businessDescription && <p className="error-message">{errors.businessDescription}</p>}
      </div>

      <div className="inputFlex">
        <p className="inputLabel">{t('OnBoarding.expectedAnnualRevenue', { currency: currencyCode })}</p> <span style={{ color: 'red' }}>*</span>
        <FormGroup>
          {['10,001 - 50,000', '50,001 - 100,000', '100,001 - 500,000', '500,000-1,000,000', '> 1,000,000+'].map((range) => (
            <FormControlLabel
              key={range}
              control={
                <Checkbox
                  checked={formData?.page1?.expectedAnnualRevenue?.includes(`${range} ${currencyCode}`) || false}
                  onChange={() => handleInputChange('page1.expectedAnnualRevenue', `${range} ${currencyCode}`)}
                />
              }
              label={`${range} ${currencyCode}`}
            />
          ))}
        </FormGroup>
        {errors.expectedAnnualRevenue && <p className="error-message">{errors.expectedAnnualRevenue}</p>}
      </div>

      <div className="inputFlex">
        <p className="inputLabel">{t('OnBoarding.numberOfEmployees')}</p> <span style={{ color: 'red' }}>*</span>
        <InputTypeText
          className="inputText"
          placeholder=""
          type='number'
          value={formData?.page1?.numberOfEmployees}
          onChange={(e) => handleInputChange('page1.numberOfEmployees', e.target.value)}
        />
        {errors.numberOfEmployees && <p className="error-message">{errors.numberOfEmployees}</p>}
      </div>
    </div>
  );
};

export default withTranslation()(Section1);
