import { createAsyncThunk } from '@reduxjs/toolkit';
import { CurrencyOption, DocumentSubmitFormData, Section1, Section2, Section3, Section4, Section5, Section6, Section7 } from './interface';
import { SuccessImage, EmailSentImage, ErrorImage, UpdateSuccessImage } from "../../../constants/Images";
import { apiHelper } from '../../../api helper';
import { showAlert } from '../swal/reducer';
import { AxiosResponse } from 'axios';
import Swal from "sweetalert2";

const token = localStorage.getItem("token")
const userid = localStorage.getItem("userId")

const SuccessToast = Swal.mixin({
  toast: true,
  position: 'top-end',
  // iconColor: 'green',
  customClass: {
    popup: 'colored-toast',
  },
  showConfirmButton: false,
  timer: 1500,
})

export const submitSection1 = createAsyncThunk<void, Section1, { rejectValue: string }>(
  'documentSubmit/submitSection1',
  async (section1Data, { rejectWithValue }) => {
    try {
      const response = await apiHelper(`onboarding/page1/${userid}`, 'post', section1Data, {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      });
      if (response) {
        SuccessToast.fire({
          icon: 'success',
          title: 'Company Info Saved.',
          iconColor: 'green'
        });
      }
    } catch (error: any) {
      console.log('Error cause:', error.cause);

      // Initialize an empty error message
      let errorMessage = "An error occurred";

      // If the error cause is an array, extract the first error message
      if (Array.isArray(error.cause) && error.cause.length > 0) {
        const firstError = error.cause[0];
        const firstErrorKey = Object.keys(firstError)[0];
        errorMessage = firstError[firstErrorKey];
      } else if (typeof error.cause === 'string') {
        errorMessage = error.cause;
      }

      Swal.fire({
        title: 'Error!',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'Ok'
      });

      return rejectWithValue(errorMessage);
    }
  }
);


export const submitSection2 = createAsyncThunk<
  void,
  Section2,
  { rejectValue: string }
>(
  'documentSubmit/submitSection2',
  async (section2Data, { rejectWithValue }) => {
    try {
      const response = await apiHelper(
        `onboarding/page2/${userid}`,
        'post',
        section2Data,
        {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      );
      if (response && response.status === 201) {
        SuccessToast.fire({
          icon: 'success',
          title: 'Company Info Saved.',
          iconColor: 'green'
        });
      } else {
        return rejectWithValue('Failed to submit Company Info');
      }
    } catch (error: any) {
      console.log('Error cause:', error.cause);

      // Display only the first error message
      let errorMessage = "An error occurred";
      if (Array.isArray(error.cause) && error.cause.length > 0) {
        const firstError = error.cause[0];
        const firstErrorKey = Object.keys(firstError)[0];
        errorMessage = firstError[firstErrorKey];
      } else if (typeof error.cause === 'string') {
        errorMessage = error.cause;
      }

      Swal.fire({
        title: 'Error!',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'Ok'
      });

      return rejectWithValue(errorMessage);
    }
  }
);

export const submitSection3 = createAsyncThunk<
  void,
  Section3,
  { rejectValue: string }
>(
  'documentSubmit/submitSection3',
  async (section3Data, { rejectWithValue }) => {
    try {
      const response = await apiHelper(
        `onboarding/page3/${userid}`,
        'post',
        section3Data,
        {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      );

      if (response) {
        SuccessToast.fire({
          icon: 'success',
          title: 'Marketing Information Saved.',
          iconColor: 'green'
        });
      }
    } catch (error: any) {
      console.log('Error cause:', error.cause);

      let errorMessage = "An error occurred";
      if (Array.isArray(error.cause) && error.cause.length > 0) {
        const firstError = error.cause[0];
        const firstErrorKey = Object.keys(firstError)[0];
        errorMessage = firstError[firstErrorKey];
      } else if (typeof error.cause === 'string') {
        errorMessage = error.cause;
      }

      Swal.fire({
        title: 'Error!',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'Ok'
      });

      return rejectWithValue(errorMessage);
    }
  }
);

export const submitSection4 = createAsyncThunk<
  void,
  Section4,
  { rejectValue: string }
>(
  'documentSubmit/submitSection4',
  async (section4Data, { rejectWithValue }) => {
    try {
      const response = await apiHelper(
        `onboarding/page4/${userid}`,
        'post',
        section4Data,
        {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      );

      if (response) {
        SuccessToast.fire({
          icon: 'success',
          title: 'Transactions Data Saved.',
          iconColor: 'green'
        });
      }
    } catch (error: any) {
      console.log('Error cause:', error.cause);

      let errorMessage = "An error occurred";
      if (Array.isArray(error.cause) && error.cause.length > 0) {
        const firstError = error.cause[0];
        const firstErrorKey = Object.keys(firstError)[0];
        errorMessage = firstError[firstErrorKey];
      } else if (typeof error.cause === 'string') {
        errorMessage = error.cause;
      }

      Swal.fire({
        title: 'Error!',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'Ok'
      });

      return rejectWithValue(errorMessage);
    }
  }
);
export const submitSection6 = createAsyncThunk<
  void,
  Section6,
  { rejectValue: string }
>(
  'documentSubmit/submitSection6',
  async (section6Data, { rejectWithValue }) => {
    try {
      const response = await apiHelper(
        `onboarding/page5/${userid}`,
        'post',
        section6Data,
        {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      );

      if (response) {
        SuccessToast.fire({
          icon: 'success',
          title: 'Payments Data Saved.',
          iconColor: 'green'
        });
      }
    } catch (error: any) {
      console.log('Error cause:', error.cause);

      let errorMessage = "An error occurred";
      if (Array.isArray(error.cause) && error.cause.length > 0) {
        const firstError = error.cause[0];
        const firstErrorKey = Object.keys(firstError)[0];
        errorMessage = firstError[firstErrorKey];
      } else if (typeof error.cause === 'string') {
        errorMessage = error.cause;
      }

      Swal.fire({
        title: 'Error!',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'Ok'
      });

      return rejectWithValue(errorMessage);
    }
  }
);
export const submitSection7 = createAsyncThunk<
  void,
  Section7,
  { rejectValue: string }
>(
  'documentSubmit/submitSection7',
  async (section7Data, { rejectWithValue }) => {
    try {
      const response = await apiHelper(
        `onboarding/page6/${userid}`,
        'post',
        section7Data,
        {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      );

      if (response) {
        SuccessToast.fire({
          icon: 'success',
          title: 'Business Card Info Saved.',
          iconColor: 'green'
        });
      }
    } catch (error: any) {
      console.log('Error cause:', error.cause);

      let errorMessage = "An error occurred";
      if (Array.isArray(error.cause) && error.cause.length > 0) {
        const firstError = error.cause[0];
        const firstErrorKey = Object.keys(firstError)[0];
        errorMessage = firstError[firstErrorKey];
      } else if (typeof error.cause === 'string') {
        errorMessage = error.cause;
      }

      Swal.fire({
        title: 'Error!',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'Ok'
      });

      return rejectWithValue(errorMessage);
    }
  }
);

export const submitSection5 = createAsyncThunk<
  void,
  Section5 | FormData | any,
  { rejectValue: string }
>(
  'documentSubmit/submitSection5',
  async (section5Data, { rejectWithValue }) => {
    try {
      let response;
      if (section5Data instanceof FormData) {
        response = await apiHelper(
          `onboarding/page7/${userid}`,
          'post',
          section5Data,
          {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        );
      } else {
        response = await apiHelper(
          `onboarding/page7/${userid}`,
          'post',
          section5Data,
          {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        );
      }

      if (response) {
        SuccessToast.fire({
          icon: 'success',
          title: 'Company Info 5 Data Saved.',
          iconColor: 'green'
        });
      }
    } catch (error: any) {
      console.log('Error cause:', error.cause);

      let errorMessage = "An error occurred";
      if (Array.isArray(error.cause) && error.cause.length > 0) {
        const firstError = error.cause[0];
        const firstErrorKey = Object.keys(firstError)[0];
        errorMessage = firstError[firstErrorKey];
      } else if (typeof error.cause === 'string') {
        errorMessage = error.cause;
      }

      Swal.fire({
        title: 'Error!',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'Ok'
      });

      return rejectWithValue(errorMessage);
    }
  }
);


export const fetchUserInfo = createAsyncThunk<
  DocumentSubmitFormData,
  string,
  { rejectValue: string }
>(
  'documentSubmit/fetchUserInfo',
  async (userId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        return rejectWithValue("Authentication token is missing");
      }

      const response = await apiHelper(
        `onboarding/getPageDetails/${userId}`,
        'get',
        { 'Content-Type': 'application/json' },
        { Authorization: `Bearer ${token}` }
      );

      if (response.status !== 200) {
        return rejectWithValue(`Unexpected status code: ${response.status}`);
      }

      const responseData = response.data?.data || {
        pageData: {
          page1: {},
          page2: {},
          page3: {},
          page4: {},
          page5: {},
          page6: {},
          page7: {},
          page8: {},
          attachedDocuments: {},
        },
        onboardingProgress: 0,
        onboardingStatus: '',
      };

      const pageData = responseData.pageData;
      const onboardingProgress = responseData.onboardingProgress || 0;
      const onboardingStatus = responseData.onboardingStatus || '';

      const {
        page1 = {},
        page2 = {},
        page3 = {},
        page4 = {},
        page5 = {},
        page6 = {},
        page7 = {},
        page8 = {},
      } = pageData;
      return {
        onboardingProgress, 
        onboardingStatus,
        page1,
        page2,
        page3,
        page4,
        page5,
        page6,
        page7,
        page8,
        // attachedDocuments,
      } as DocumentSubmitFormData;
    } catch (error: any) {
      let errorMessage = "An unknown error occurred";
      if (error?.cause) {
        errorMessage = Array.isArray(error.cause)
          ? error.cause[0]?.message || errorMessage
          : error.cause;
      }

      Swal.fire({
        title: 'Error!',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'Ok',
      });

      return rejectWithValue(errorMessage);
    }
  }
);

export const updateCompanyInfoField = createAsyncThunk<
  void,
  { userId: string, field: string, value: string },
  { rejectValue: string }
>(
  'documentSubmit/updateCompanyInfoField',
  async ({ userId, field, value }, { rejectWithValue }) => {
    try {
      const response = await apiHelper(
        `onboarding/onboardingCompanyInfo/${userId}`,
        'put',
        { [field]: value },
        {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      );

      if (response.status !== 201) {
        return rejectWithValue('Failed to update field');
      }
      if (response) {
        SuccessToast.fire({
          icon: 'success',
          title: 'Data Updated Successfully.',
          iconColor: 'green'
        });
      }
    } catch (error) {
      return rejectWithValue('Error updating field: ' + (error as Error).message);
    }
  }
);

export const submitDocuments = createAsyncThunk<
  boolean,
  FormData,
  { rejectValue: string }
>(
  'documentSubmit/submitDocuments',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await apiHelper(
        `onboarding/page8/${userid}`,
        'post',
        formData,
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        }
      );

      if (response.status === 201) {
        SuccessToast.fire({
          icon: 'success',
          title: 'All Documents Submitted Successfully.',
          iconColor: 'green',
        });
        return true;
      } else {
        return rejectWithValue('Failed to submit documents');
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: `${error}`,
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return rejectWithValue(`${(error as Error).message}`);
    }
  }
);

export const updateDocuments = createAsyncThunk<
  boolean,
  FormData,
  { rejectValue: string }
>(
  'documentSubmit/updateDocuments',
  async (formData, { rejectWithValue }) => {
    console.log("1, ", formData)
    try {
      const response = await apiHelper(
        `onboarding/page8/${userid}`,
        'put',
        formData,
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        }
      );
      console.log("2, ", response)

      if (response.status === 201) {
        SuccessToast.fire({
          icon: 'success',
          title: 'Documents Updated Successfully.',
          iconColor: 'green',
        });
        return true;
      } else {
        return rejectWithValue('Failed to update documents');
      }
    } catch (error) {
      console.log("3, ", error)

      Swal.fire({
        title: 'Error!',
        text: `${error}`,
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return rejectWithValue(`${(error as Error).message}`);
    }
  }
);


export const fetchCompanyInfoByCVR = createAsyncThunk<
  any,
  string,
  { rejectValue: string }
>(
  'documentSubmit/fetchCompanyInfoByCVR',
  async (registrationNumber, { rejectWithValue }) => {
    try {
      const response = await apiHelper(
        `onboarding/companyInfoByCVR?businessRegistrationNumber=${registrationNumber}`,
        'get',
        {
          'Content-Type': 'application/json',
        },
        {
          Authorization: `Bearer ${token}`,
        },
      );

      if (response.status !== 200) {
        return rejectWithValue('Failed to fetch company info');
      }
      if (response) {
        SuccessToast.fire({
          icon: 'success',
          title: 'Company Data Fetched by CVR.',
          iconColor: 'green'
        });
      }
      return response.data;
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: `${error}`,
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return rejectWithValue('Error fetching company info by CVR: ' + (error as Error).message);
    }
  }
);