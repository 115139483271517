import React, { useState, useEffect } from "react";
import HamburgerMenu from "../../components/shared/HamburgerMenu";
import LeftSideBar from "../../components/shared/LeftSidebar";
import RightSideBar from "../../components/shared/RightSidebar";
import { t } from "i18next";
import InterestAccountCard from "../../components/shared/InterestAccountCard";
import Footer from "../../components/shared/Footer";
import AccountSwitch from "../../components/shared/AccountsSwitch";
import InterestAccountPageCard from "../../components/shared/InterestAccount";
import InputComponent from "../../components/shared/Input/Index";
import { Form, Spinner } from "react-bootstrap";
import "./Index.css";
import { errorMsgs, regexStrConst } from "../../constants/stringConstants";




const InterestAccount = () => {
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [vatNum, setVatNum] = useState("");
  const [vatNumError, setVatNumError] = useState("");
  const [contact, setcontact] = useState("");
  const [contactError, setContactError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [depositTime, setDepositTime] = useState("");
  const [depositTimeError, setDepositTimeError] = useState("");
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState("");
  const [timeFrame, setTimeFrame] = useState("");
  const [timeFrameError, setTimeFrameError] = useState("");
  const [show, setShow] = useState(false);



  const onChangeCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    setCompanyError("");
    setCompany(item);
  };
  const onChangeVatNum = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    setVatNumError("");
    setVatNum(item);
  };
  const onChangeContact = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    setContactError("");
    setcontact(item);
  };
  const onChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    setPhoneError("");
    setPhone(item);
  };
  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    setEmailError("");
    setEmail(item);
  };
  const onChangeDepositTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    setDepositTimeError("");
    setDepositTime(item);
  };
  const onChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    setAmountError("");
    setAmount(item);
  };
  const onChangeTimeFrame = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    setTimeFrameError("");
    setTimeFrame(item);
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (validate()) {
      setShow(true);
    }
  };

  const validate = () => {
    if (company === "") setCompanyError("Please enter company name");
    if (vatNum === "") setVatNumError("Please enter vat number");
    if (contact === "") setContactError("Please enter contact");
    if (phone === "") setPhoneError("Please enter phone");
    if (email === "") setEmailError("Please enter email");
    if (depositTime === "") setDepositTimeError("Please enter deposit time");
    if (amount === "") setAmountError("Please enter amount");
    if (timeFrame === "") setTimeFrameError("Please enter time frame");

    return company === "" || companyError
      || vatNum === "" || vatNumError
      || contact === "" || contactError
      || phone === "" || phoneError
      || email === "" || emailError
      || depositTime === "" || depositTimeError
      || amount === "" || amountError
      || timeFrame === "" || timeFrameError
      ? false
      : true;
  };

  return (
    <>
      {/* <div className="hamburgerBackground">
        <HamburgerMenu name="interestAccount" />
      </div> */}
      <div className="Interest-account-main">
        {/* <div className="left_sidebar position-fixed">
          <LeftSideBar name="interestAccount" />
        </div> */}
        <div className="inner__content">
          <div className="container pb-5">
            <div className="row">
              <div className="col-12">
                <div className="heading_title">
                  <h2 className="interestAccount-heading">
                    {t("interestAccountCard.title")}
                  </h2>
                </div>
              </div>
              <div className="col-12">
                <div className="card-details"></div>
              </div>
              <div className="Acc-switch">
                <AccountSwitch />
              </div>
              <InterestAccountPageCard />
              <div className="interest-account-container">
                <InterestAccountCard />
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
        <div className="inner__content position-relative pb-5">
          <h1 className="company_title">{t("interestAccount.title")}</h1>
          <div className="row">
            <div className="col-6">
              <div className="company-card-box">
                <p>Application form for interest-bearing account</p>
                <p>Overview</p>
                <p>The interest-bearing account is a joint account where a select group of OneMoneyWay&apos;s customers can deposit surplus capital to be used in 3, 6, or 12 months and earn an annual interest rate of 7% on the capital. The capital is invested in marketing, which generates a return on ad spend (ROAS) of 10 to 12X, and OneMoneyWay shares the profits with the customer.</p><p>Objective of the interest-bearing account</p><ol><li>Mission: OneMoneyWay&apos;s mission is to create innovative financial solutions for the benefit of small and medium-sized businesses across Europe.</li><li>Interest-bearing account: OneMoneyWay has developed the interest-bearing account product to help customers earn interest on their money instead of losing value due to current inflation.</li></ol><p>Specifications</p><ol><li>The contract will be entered into between your company and OneMoneyWay. OneMoneyWay cannot enter into a contract with an individual.</li><li>OneMoneyWay is not allowed to offer interest on customers regular accounts, but it can offer interest on investment and loan products. Therefore, the agreement will be structured as a loan document, where OneMoneyWay can offer a 7% annual interest rate.</li><li>Once the contract is signed by both parties, you will have access to view your interest-bearing account on your user profile at app.onemoneyway.com. This allows you to track the growth of your capital on a monthly basis.</li></ol>
              </div>
            </div>
            <div className="col-6">
              <div className="company-card-box">
                <form className="company-form" onSubmit={handleSubmit}>
                  <p className="comment-inp-text">{t("interestAccount.company")}</p>
                  <InputComponent
                    // placeholder={t("sendFunds.form.comments")}
                    onChange={onChangeCompany}
                    errorMsg={companyError}
                  />
                  <p className="comment-inp-text">{t("interestAccount.vatNumber")}</p>
                  <InputComponent
                    // placeholder={t("sendFunds.form.comments")}
                    onChange={onChangeVatNum}
                    errorMsg={vatNumError}
                  />
                  <p className="comment-inp-text">{t("interestAccount.contact")}</p>
                  <InputComponent
                    // placeholder={t("sendFunds.form.comments")}
                    onChange={onChangeContact}
                    errorMsg={contactError}
                  />
                  <p className="comment-inp-text">{t("interestAccount.phone")}</p>
                  <InputComponent
                    // placeholder={t("sendFunds.form.comments")}
                    onChange={onChangePhone}
                    errorMsg={phoneError}
                  />
                  <p className="comment-inp-text">{t("interestAccount.email")}</p>
                  <InputComponent
                    // placeholder={t("sendFunds.form.comments")}
                    onChange={onChangeEmail}
                    errorMsg={emailError}
                  />
                  <p className="comment-inp-text">{t("interestAccount.depositTime")}</p>
                  <InputComponent
                    // placeholder={t("sendFunds.form.comments")}
                    onChange={onChangeDepositTime}
                    errorMsg={depositTimeError}
                  />
                  <p className="comment-inp-text">{t("interestAccount.amount")}</p>
                  <InputComponent
                    // placeholder={t("sendFunds.form.comments")}
                    onChange={onChangeAmount}
                    errorMsg={amountError}
                  />
                  <p className="comment-inp-text">{t("interestAccount.timeFrame")}</p>
                  <InputComponent
                    // placeholder={t("sendFunds.form.comments")}
                    onChange={onChangeTimeFrame}
                    errorMsg={timeFrameError}
                  />
                  <div className="btn-send">
                    <button className="convert-btn" type="submit">
                      {loading ? (
                        <Spinner animation={"border"} />
                      ) : (
                        `${t("convert.form.send")}`
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>

          </div>

          <Footer />
        </div>
        <RightSideBar exchangeRates accountSwitch summaryBox interestAccount />
      </div>
    </>);

}

export default InterestAccount