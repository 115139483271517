import React from 'react';
import { DocumentSubmitFormData } from '../../redux/toolkit/documentSubmit/interface';
import InputTypeText from '../../components/shared/Input/Basic/InputTypeText';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Information } from '../../constants/Images';

type Section4Props = {
  formData: DocumentSubmitFormData;
  errors: { [key: string]: string };
  handleInputChange: (fieldPath: string, value: any, index?: number) => void;
};

const Section4: React.FC<Section4Props> = ({ formData, errors, handleInputChange }) => {
  const { t } = useTranslation();
  const countryCode = localStorage.getItem('selectedCountryCode');

  return (
    <div>
      <div className="inputFlex">
        <p className="inputLabel">{t('OnBoarding.incomingPaymentsTitle')}</p> <span style={{ color: 'red' }}>*</span>
        <div style={{ display: 'flex', gap: 14, marginTop: 10 }}>
          <img src={Information} alt="icon" width={16} height={16} style={{ marginTop: 4 }} />
          <div>
            <p style={{ marginBottom: 5, fontSize: 14 }}>{t('OnBoarding.incomingPaymentsDescription')}</p>
          </div>
        </div>
        <FormGroup>
          {['0-10', '11-50', '51-100', '100+'].map((range) => (
            <FormControlLabel
              key={range}
              control={
                <Checkbox
                  checked={formData?.page4?.incomingPaymentsPerMonth?.includes(range) || false}
                  onChange={() => handleInputChange('page4.incomingPaymentsPerMonth', range)}
                />
              }
              label={range}
            />
          ))}
        </FormGroup>
        {errors.incomingPaymentsPerMonth && <p className="error-message">{errors.incomingPaymentsPerMonth}</p>}
      </div>

      <div className="inputFlex">
        <p className="inputLabel">{t('OnBoarding.outgoingPaymentsTitle')}</p> <span style={{ color: 'red' }}>*</span>
        <div style={{ display: 'flex', gap: 14, marginTop: 10 }}>
          <img src={Information} alt="icon" width={16} height={16} style={{ marginTop: 4 }} />
          <div>
            <p style={{ marginBottom: 5, fontSize: 14 }}>{t('OnBoarding.outgoingPaymentsDescription')}</p>
          </div>
        </div>
        <FormGroup>
          {['0-10', '11-50', '51-100', '100+'].map((range) => (
            <FormControlLabel
              key={range}
              control={
                <Checkbox
                  checked={formData?.page4?.outgoingPaymentsPerMonth?.includes(range) || false}
                  onChange={() => handleInputChange('page4.outgoingPaymentsPerMonth', range)}
                />
              }
              label={range}
            />
          ))}
        </FormGroup>
        {errors.outgoingPaymentsPerMonth && <p className="error-message">{errors.outgoingPaymentsPerMonth}</p>}
      </div>

      <div className="inputFlex">
        <p className="inputLabel">
          {t('OnBoarding.monthlyIncomingPayments', { currency: formData.page1.selectedCurrency || countryCode })}
        </p> <span style={{ color: 'red' }}>*</span>
        <div style={{ display: 'flex', gap: 14, marginTop: 10 }}>
          <img src={Information} alt="icon" width={16} height={16} style={{ marginTop: 4 }} />
          <div>
            <p style={{ marginBottom: 5, fontSize: 14 }}>
              {t('OnBoarding.monthlyIncomingPaymentsDescription', {
                currency: formData.page1.selectedCurrency || countryCode,
              })}
            </p>
            <p style={{ marginBottom: 5, fontSize: 14 }}>
              {t('OnBoarding.noteCurrencyConversion', { currency: formData.page1.selectedCurrency || countryCode })}
            </p>
          </div>
        </div>
        <InputTypeText
          className="inputText"
          type="number"
          value={formData?.page4?.monthlyIncomingPayments}
          onChange={(e) => handleInputChange('page4.monthlyIncomingPayments', e.target.value)}
        />
        {errors.monthlyIncomingPayments && <p className="error-message">{errors.monthlyIncomingPayments}</p>}
      </div>

      <div className="inputFlex">
        <p className="inputLabel">
          {t('OnBoarding.monthlyOutgoingPayments', { currency: formData.page1.selectedCurrency || countryCode })}
        </p> <span style={{ color: 'red' }}>*</span>
        <div style={{ display: 'flex', gap: 14, marginTop: 10 }}>
          <img src={Information} alt="icon" width={16} height={16} style={{ marginTop: 4 }} />
          <div>
            <p style={{ marginBottom: 5, fontSize: 14 }}>
              {t('OnBoarding.monthlyOutgoingPaymentsDescription', {
                currency: formData.page1.selectedCurrency || countryCode,
              })}
            </p>
            <p style={{ marginBottom: 5, fontSize: 14 }}>
              {t('OnBoarding.noteCurrencyConversion', { currency: formData.page1.selectedCurrency || countryCode })}
            </p>
          </div>
        </div>
        <InputTypeText
          className="inputText"
          type="number"
          value={formData?.page4?.monthlyOutgoingPayments}
          onChange={(e) => handleInputChange('page4.monthlyOutgoingPayments', e.target.value)}
        />
        {errors.monthlyOutgoingPayments && <p className="error-message">{errors.monthlyOutgoingPayments}</p>}
      </div>
    </div>
  );
};

export default Section4;
