import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActiveUserGif } from "../../constants/Images";
import { fetchUserInfo } from "../../redux/toolkit/documentSubmit/operation";
import { AppDispatch, RootState } from '../../redux/store';
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, LinearProgress, linearProgressClasses, styled } from "@mui/material";
import BasicButton from "../../components/shared/Buttons/Basic";
import { useNavigate } from "react-router-dom";
import { apiHelper } from "../../api helper";
import { DocumentSubmitFormData } from "../../redux/toolkit/documentSubmit/interface";

type UserInfoResponse = {
  onboardingProgress: {
    onboardingProgress?: number;
    onboardingStatus?: string;
  }
};

const ActiveUser = (props: any) => {
  const userId: any = localStorage.getItem("userId");
  const token: any = localStorage.getItem("token");
  const navigate = useNavigate();
  const formData = useSelector((state: RootState) => state.documentSubmit);
  const dispatch = useDispatch<AppDispatch>();
  const [progressValue, setProgressValue] = useState<number>(0);
  const [progressStatus, setProgressStatus] = useState<string | undefined>("");
  const [isDocumentsCompleted, setIsDocumentsCompleted] = useState<boolean>(false);
  const { t } = useTranslation();


  useEffect(() => {
    const fetchData = async () => {

      try {
        const response: DocumentSubmitFormData = await dispatch(fetchUserInfo(userId)).unwrap();
        setProgressValue(response.onboardingProgress)
        setProgressStatus(response.onboardingStatus)
      } catch (error) {
        console.error('Error fetching user info:', error);
        setProgressValue(0);
      }
    };

    fetchData();
  }, [dispatch, userId]);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 20,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 10,
      backgroundColor: theme.palette.mode === 'dark' ? '#006FF4' : '#006FF4',
    },
  }));

  return (
    <div
      style={{ padding: 30, marginBottom: 30, flex: 1 }}
      className="nonActiveUserDiv"
    >
      <img
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          width: "170px",
          marginTop: 10,
          marginBottom: 10,
        }}
        src={ActiveUserGif}
        alt="loading..."
        className="non-active-user-img"
      />
      <p className="thanksForRegisteringtext">
        {t("complianceMode.parahfirst")}
      </p>
      <p className="detialstext" style={{ marginBottom: 15 }}>
        {t("complianceMode.parahsecond")}
      </p>
      <p className="detialstext">
        {t("complianceMode.parahfourth")}
      </p>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h3 style={{ fontSize: 20 }}>
            {t("OnBoarding.OnBoard Progress")}: {progressValue !== undefined && progressValue !== null ? `${progressValue}%` : <CircularProgress size="24px" />}
          </h3>
          <h3 style={{
            color: progressValue === 100 && isDocumentsCompleted ? 'green' : 'red',
            fontSize: 20
          }}>
            {progressStatus !== undefined && progressStatus !== null ? progressStatus : <CircularProgress />}
          </h3>
        </div>
        <div style={{ marginBottom: 20 }}>
          <BorderLinearProgress variant="determinate" value={progressValue} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'end', gap: 15 }}>
          <BasicButton text={'Go to OnBoarding'} style={{ width: 'max-content' }} onClick={() => navigate('/documentsubmit')} />
        </div>
      </div>
    </div>
  );
};

export default ActiveUser;
