import React, { useEffect } from 'react';
import { FormGroup, TextField, Autocomplete } from '@mui/material';
import { DocumentSubmitFormData } from '../../redux/toolkit/documentSubmit/interface';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrencies } from '../../redux/toolkit/documentSubmit/reducer';
import { RootState, AppDispatch } from '../../redux/store';
import { Information } from '../../constants/Images';

type Section6Props = {
  handleInputChange: (fieldPath: string, value: any, index?: number) => void;
  errors: { [key: string]: string };
  formData: DocumentSubmitFormData;
};

const Section6: React.FC<Section6Props> = ({ formData, handleInputChange, errors }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { currencies, loading } = useSelector((state: RootState) => state.currencies);

  useEffect(() => {
    dispatch(fetchCurrencies());
  }, [dispatch]);

  return (
    <div>
      <h2 style={{ fontSize: 24, fontWeight: 600, marginBottom: 20 }}>{t('OnBoarding.countriesOfBusiness')}</h2>

      {/* Incoming Payments Countries */}
      <div className="inputFlex">
        <p style={{ marginBottom: 10 }} className="inputLabel">{t('OnBoarding.incomingPaymentCountries')}</p>
        <span style={{ color: 'red' }}>*</span>
        <div style={{ display: 'flex', gap: 14, marginTop: 10 }}>
          <img src={Information} alt="icon" width={16} height={16} style={{ marginTop: 4 }} />
          <div>
            <p style={{ marginBottom: 5, fontSize: 14 }}>{t('OnBoarding.incomingPaymentDescription')}</p>
          </div>
        </div>
        <Autocomplete
          multiple
          options={currencies.map((ele) => ele.country)}
          value={formData?.page5?.incomingPaymentCountries || []}
          onChange={(event, newValue) => handleInputChange('page5.incomingPaymentCountries', newValue)}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" style={{ borderRadius: 20 }} />
          )}
        />
        {errors.incomingPaymentCountries && <p className="error-message">{errors.incomingPaymentCountries}</p>}
      </div>

      {/* Outgoing Payments Countries */}
      <div className="inputFlex">
        <p style={{ marginBottom: 10 }} className="inputLabel">{t('OnBoarding.outgoingPaymentCountries')}</p>
        <span style={{ color: 'red' }}>*</span>
        <div style={{ display: 'flex', gap: 14, marginTop: 10 }}>
          <img src={Information} alt="icon" width={16} height={16} style={{ marginTop: 4 }} />
          <div>
            <p style={{ marginBottom: 5, fontSize: 14 }}>{t('OnBoarding.outgoingPaymentDescription')}</p>
          </div>
        </div>
        <Autocomplete
          multiple
          options={currencies.map((ele) => ele.country)}
          value={formData?.page5?.outgoingPaymentCountries || []}
          onChange={(event, newValue) => handleInputChange('page5.outgoingPaymentCountries', newValue)}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" style={{ borderRadius: 20 }} />
          )}
        />
        {errors.outgoingPaymentCountries && <p className="error-message">{errors.outgoingPaymentCountries}</p>}
      </div>

      <h2 style={{ fontSize: 24, fontWeight: 600, marginBottom: 20 }}>{t('OnBoarding.currencies')}</h2>

      {/* Incoming Currencies */}
      <div className="inputFlex">
        <p style={{ marginBottom: 10 }} className="inputLabel">{t('OnBoarding.incomingCurrencies')}</p>
        <span style={{ color: 'red' }}>*</span>
        <div style={{ display: 'flex', gap: 14, marginTop: 10 }}>
          <img src={Information} alt="icon" width={16} height={16} style={{ marginTop: 4 }} />
          <div>
            <p style={{ marginBottom: 5, fontSize: 14 }}>{t('OnBoarding.incomingCurrenciesDescription')}</p>
          </div>
        </div>
        <Autocomplete
          multiple
          options={currencies.map((currency) => currency.code)}
          value={formData?.page5?.incomingCurrencies || []}
          loading={loading}
          onChange={(event, newValue) => handleInputChange('page5.incomingCurrencies', newValue)}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" style={{ borderRadius: 20 }} />
          )}
        />
        {errors.incomingCurrencies && <p className="error-message">{errors.incomingCurrencies}</p>}
      </div>

      {/* Outgoing Currencies */}
      <div className="inputFlex">
        <p style={{ marginBottom: 10 }} className="inputLabel">{t('OnBoarding.outgoingCurrencies')}</p>
        <span style={{ color: 'red' }}>*</span>
        <div style={{ display: 'flex', gap: 14, marginTop: 10 }}>
          <img src={Information} alt="icon" width={16} height={16} style={{ marginTop: 4 }} />
          <div>
            <p style={{ marginBottom: 5, fontSize: 14 }}>{t('OnBoarding.outgoingCurrenciesDescription')}</p>
          </div>
        </div>
        <Autocomplete
          multiple
          options={currencies.map((currency) => currency.code)}
          value={formData?.page5?.outgoingCurrencies || []}
          loading={loading}
          onChange={(event, newValue) => handleInputChange('page5.outgoingCurrencies', newValue)}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" />
          )}
        />
        {errors.outgoingCurrencies && <p className="error-message">{errors.outgoingCurrencies}</p>}
      </div>
    </div>
  );
};

export default Section6;
