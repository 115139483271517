import React from 'react';
import { DocumentSubmitFormData } from '../../redux/toolkit/documentSubmit/interface';
import InputTypeText from '../../components/shared/Input/Basic/InputTypeText';
import { Information } from '../../constants/Images';
import { useTranslation } from 'react-i18next';

type Section3Props = {
  formData: DocumentSubmitFormData;
  errors: { [key: string]: string };
  handleInputChange: (fieldPath: string, value: any, index?: number) => void;
};

const Section3: React.FC<Section3Props> = ({ formData, errors, handleInputChange }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="inputFlex">
        <p className="inputLabel">{t('OnBoarding.marketingQuestion')}</p> <span style={{ color: 'red' }}>*</span>
        <div style={{ display: 'flex', gap: 14, marginTop: 10 }}>
          <img src={Information} alt="icon" width={16} height={16} style={{ marginTop: 4 }} />
          <div>
            <p style={{ marginBottom: 5, fontSize: 14 }}>{t('OnBoarding.marketingDescribeClients')}</p>
            <p style={{ marginBottom: 5, fontSize: 14 }}>{t('OnBoarding.marketingFindingClients')}</p>
            <p style={{ fontSize: 14 }}>{t('OnBoarding.marketingNoNeed')}</p>
          </div>
        </div>
        <InputTypeText
          className="inputText"
          placeholder=""
          value={formData?.page3?.marketingDescription}
          onChange={(e) => handleInputChange('page3.marketingDescription', e.target.value)}
        />
        {errors.marketingDescription && <p className="error-message">{errors.marketingDescription}</p>}
      </div>

      <div className="inputFlex">
        <p className="inputLabel">{t('OnBoarding.websiteLink')}</p> <span style={{ color: 'red' }}>*</span>
        <div style={{ display: 'flex', gap: 14, marginTop: 10 }}>
          <img src={Information} alt="icon" width={16} height={16} style={{ marginTop: 4 }} />
          <div>
            <p style={{ marginBottom: 5, fontSize: 14 }}>{t('OnBoarding.websiteDescription')}</p>
          </div>
        </div>
        <InputTypeText
          className="inputText"
          placeholder=""
          value={formData?.page3?.websiteUrl}
          onChange={(e) => handleInputChange('page3.websiteUrl', e.target.value)}
        />
        {errors.websiteUrl && <p className="error-message">{errors.websiteUrl}</p>}
      </div>

      <div className="inputFlex">
        <p className="inputLabel">{t('OnBoarding.facebookPage')}</p> <span style={{ color: 'red' }}>*</span>
        <div style={{ display: 'flex', gap: 14, marginTop: 10 }}>
          <img src={Information} alt="icon" width={16} height={16} style={{ marginTop: 4 }} />
          <div>
            <p style={{ marginBottom: 5, fontSize: 14 }}>{t('OnBoarding.facebookDescription')}</p>
          </div>
        </div>
        <InputTypeText
          className="inputText"
          placeholder=""
          value={formData?.page3?.facebookPage}
          onChange={(e) => handleInputChange('page3.facebookPage', e.target.value)}
        />
        {errors.facebookPage && <p className="error-message">{errors.facebookPage}</p>}
      </div>

      <div className="inputFlex">
        <p className="inputLabel">{t('OnBoarding.instagramName')}</p> <span style={{ color: 'red' }}>*</span>
        <div style={{ display: 'flex', gap: 14, marginTop: 10 }}>
          <img src={Information} alt="icon" width={16} height={16} style={{ marginTop: 4 }} />
          <div>
            <p style={{ marginBottom: 5, fontSize: 14 }}>{t('OnBoarding.instagramDescription')}</p>
          </div>
        </div>
        <InputTypeText
          className="inputText"
          placeholder=""
          value={formData?.page3?.instagramName}
          onChange={(e) => handleInputChange('page3.instagramName', e.target.value)}
        />
        {errors.instagramName && <p className="error-message">{errors.instagramName}</p>}
      </div>
    </div>
  );
};

export default Section3;
