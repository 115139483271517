import React, { useState } from 'react';
import { FormControl, RadioGroup, FormControlLabel, Radio, Tooltip } from '@mui/material';
import { DocumentSubmitFormData, DirectorInformation, BeneficiaryInformation } from '../../redux/toolkit/documentSubmit/interface';
import InputTypeText from '../../components/shared/Input/Basic/InputTypeText';
import BasicButton from '../../components/shared/Buttons/Basic';
import { useTranslation } from 'react-i18next';
import Accordion from 'react-bootstrap/Accordion';
import AccordianHeaderData from '../../components/shared/Accordian/AccordianSummary';

type Section5Props = {
  formData: DocumentSubmitFormData;
  errors: { [key: string]: string };
  directors: DirectorInformation[];
  beneficialOwners: BeneficiaryInformation[];
  handleAddDirector: () => void;
  handleAddBeneficialOwner: () => void;
  handleDeleteDirector: (index: number) => void;
  handleDeleteBeneficialOwner: (index: number) => void;
  handleDirectorInputChange: (
    index: number,
    field: keyof DirectorInformation | keyof DirectorInformation["address"],
    value: string
  ) => void;
  handleBeneficialOwnerInputChange: (index: number, field: keyof BeneficiaryInformation, value: string) => void;
  handleInputChange: (fieldPath: string, value: any, index?: number) => void;
  loading: boolean;
};

const Section5Page: React.FC<Section5Props> = ({
  formData,
  errors,
  directors,
  beneficialOwners,
  handleAddDirector,
  handleDeleteDirector,
  handleDirectorInputChange,
  handleInputChange,
  handleAddBeneficialOwner,
  handleDeleteBeneficialOwner,
  handleBeneficialOwnerInputChange,
  loading,
}) => {
  const { t } = useTranslation();

  const renderDirectorFields = (director: DirectorInformation, index: number) => (
    <div>
      {["firstName", "lastName", "phoneNumber", "email"].map((field) => (
        <div key={field} className="inputFlex">
          <p className="inputLabel">{t(`OnBoarding.directors.${field}`, { index: index + 1 })}</p>
          <span style={{ color: 'red' }}>*</span>
          <InputTypeText
            className="inputText"
            value={(director as any)[field]}
            onChange={(e) => handleDirectorInputChange(index, field as keyof DirectorInformation, e.target.value)}
          />
          {errors[`director_${index}_${field}`] && <p className="error-message">{errors[`director_${index}_${field}`]}</p>}
        </div>
      ))}
      {["street", "postalCode", "city", "country"].map((field) => (
        <div key={field} className="inputFlex">
          <p className="inputLabel">{t(`OnBoarding.directors.address.${field}`, { index: index + 1 })}</p>
          <span style={{ color: 'red' }}>*</span>
          <InputTypeText
            className="inputText"
            value={(director.address as any)[field]}
            onChange={(e) => handleDirectorInputChange(index, field as keyof DirectorInformation["address"], e.target.value)}
          />
          {errors[`director_${index}_${field}`] && <p className="error-message">{errors[`director_${index}_${field}`]}</p>}
        </div>
      ))}
    </div>
  );

  const renderBeneficialOwnerFields = (owner: BeneficiaryInformation, index: number) => (
    <div>
      {["firstName", "lastName", "phoneNumber", "email", "ownershipPercentage"].map((field) => (
        <div key={field} className="inputFlex">
          <p className="inputLabel">{t(`OnBoarding.beneficialOwner.${field}`)}</p>
          <InputTypeText
            value={(owner as any)[field]}
            onChange={(e) => handleBeneficialOwnerInputChange(index, field as keyof BeneficiaryInformation, e.target.value)}
          />
        </div>
      ))}
    </div>
  );

  return (
    <div>
      {
        formData.page1.companyType === 'Sole Trader' || formData.page1.companyType === 'Enkeltmandsvirksomhed' ?
        null
        :
        <div className="inputFlex">
          <div>
            <p className="inputLabel">{t('OnBoarding.isUboSameAsDirector')}</p> <span style={{ color: 'red' }}>*</span>
          </div>
          <FormControl>
            <RadioGroup
              value={formData.page7.isUboSameAsDirector}
              onChange={(e) => {
                const selectedValue = e.target.value === "true";
                handleInputChange('page7.isUboSameAsDirector', e.target.value === "true")
                localStorage.setItem('isUboSameAsDirector', JSON.stringify(selectedValue));
              }}
            >
              <FormControlLabel value="true" control={<Radio />} label={t('OnBoarding.yes')} />
              <FormControlLabel value="false" control={<Radio />} label={t('OnBoarding.no')} />
            </RadioGroup>
          </FormControl>
          {errors.isUboSameAsDirector && <p className="error-message">{errors.isUboSameAsDirector}</p>}
        </div>
      }
      {
        formData.page1.companyType === 'Sole Trader'|| formData.page1.companyType === 'Enkeltmandsvirksomhed' || formData.page7.isUboSameAsDirector === true ?
          null
          :
          <>
            <div className='inputFlex'>
              <p className="inputLabel">{t('OnBoarding.directorSoleOwner')}</p> <span style={{ color: 'red' }}>*</span>
              <div>
                <FormControl>
                  <RadioGroup
                    value={formData.page7.isDirectorSoleOwner}
                    onChange={(e) =>
                      handleInputChange('page7.isDirectorSoleOwner', e.target.value === "true")
                    }
                  >
                    <FormControlLabel value="true" control={<Radio />} label={t('OnBoarding.yes')} />
                    <FormControlLabel value="false" control={<Radio />} label={t('OnBoarding.no')} />
                  </RadioGroup>
                </FormControl>
              </div>
              {errors.isDirectorSoleOwner && <p className="error-message">{errors.isDirectorSoleOwner}</p>}
            </div>
            <h2 style={{ fontSize: 24, fontWeight: 600, marginBottom: 20 }}>{t('OnBoarding.directorsSection')}</h2>
            <Accordion style={{ marginBottom: '15px' }} defaultActiveKey="0">
              {directors.map((director, index) => (
                <Accordion.Item eventKey={`${index}`} key={index}>
                  <AccordianHeaderData accordianTitle={`${t('OnBoarding.directors.title')} ${index + 1}`} imageHidden />
                  <Accordion.Body>
                    {renderDirectorFields(director, index)}
                    {index > 0 && (
                      <div style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}>
                        <span
                          style={{
                            color: "red",
                            cursor: "pointer",
                            padding: "4px 8px",
                            border: "1px solid red",
                            borderRadius: "8px",
                          }}
                          onClick={() => handleDeleteDirector(index)}
                        >
                          {t('OnBoarding.deleteDirector', { index: index + 1 })}
                        </span>
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            {
              formData.page7.isDirectorSoleOwner === false ?
                <div style={{ margin: "15px 0px" }}>
                  <BasicButton text={t("OnBoarding.addMore")} onClick={handleAddDirector} />
                </div>
                :
                null
            }
          </>
      }

      <div>
        <Tooltip title="Provide details of individuals with significant ownership or control over the business.">
          <h2 style={{ fontSize: 24, fontWeight: 600, marginBottom: 20, width: 'max-content' }}>{t('OnBoarding.beneficialOwner.title')}</h2>
        </Tooltip>
      </div>
      <p>{t('OnBoarding.beneficialOwner.description')}</p>

      <div style={{ marginTop: 15 }} className='inputFlex'>
        <p className="inputLabel">{t('OnBoarding.hasMultipleOwners')}</p> <span style={{ color: 'red' }}>*</span>
        <div>
          <FormControl>
            <RadioGroup
              value={formData.page7.hasMultipleOwners}
              onChange={(e) =>
                handleInputChange('page7.hasMultipleOwners', e.target.value === "true")
              }
            >
              <FormControlLabel value="true" control={<Radio />} label={t('OnBoarding.yes')} />
              <FormControlLabel value="false" control={<Radio />} label={t('OnBoarding.no')} />
            </RadioGroup>
          </FormControl>
        </div>
        {errors.hasMultipleOwners && <p className="error-message">{errors.hasMultipleOwners}</p>}
      </div>

      <div>
        {/* Existing Director Section */}
        <Accordion style={{ marginBottom: '15px' }} defaultActiveKey="0">
          {beneficialOwners.map((owner, index) => (
            <Accordion.Item eventKey={`${index}`} key={index}>
              <AccordianHeaderData
                accordianTitle={`Beneficial Owner ${index + 1}`}
                imageHidden
              />
              <Accordion.Body>
                {renderBeneficialOwnerFields(owner, index)}
                {index > 0 && (
                  <div style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}>
                    <span
                      style={{
                        color: "red",
                        cursor: "pointer",
                        padding: "4px 8px",
                        border: "1px solid red",
                        borderRadius: "8px",
                      }}
                      onClick={() => handleDeleteBeneficialOwner(index)}
                    >
                      {t('OnBoarding.deleteBeneficialOwner', { index: index + 1 })}
                    </span>
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
        {
          formData.page7.hasMultipleOwners === true ?
            <div style={{ margin: "15px 0px" }}>
              <BasicButton
                text={t("OnBoarding.addMore")}
                onClick={handleAddBeneficialOwner}
              />
            </div>
            :
            null
        }

      </div>

      <div className="inputFlex">
        <p className="inputLabel">{t('OnBoarding.isOwnedByAnotherCompany')}</p> <span style={{ color: "red" }}>*</span>
        <div>
          <FormControl>
            <RadioGroup
              value={formData.page7.isOwnedByAnotherCompany}
              onChange={(e) =>
                handleInputChange('page7.isOwnedByAnotherCompany', e.target.value === "true")
              }
            >
              <FormControlLabel value="true" control={<Radio />} label={t('OnBoarding.yes')} />
              <FormControlLabel value="false" control={<Radio />} label={t('OnBoarding.no')} />
            </RadioGroup>
          </FormControl>
        </div>
        {errors.isOwnedByAnotherCompany && <p className="error-message">{errors.isOwnedByAnotherCompany}</p>}
      </div>

      {formData.page7.isOwnedByAnotherCompany === true && (
        <>
          <div className="inputFlex">
            <p className="inputLabel">{t('OnBoarding.owningCompany.fullName')}</p> <span style={{ color: "red" }}>*</span>
            <InputTypeText
              className="inputText"
              placeholder=""
              value={formData?.page7?.owningCompanyDetails?.companyName || ""}
              onChange={(e) => handleInputChange("page7.owningCompanyDetails.companyName", e.target.value)}
            />
          </div>
          <div className="inputFlex">
            <p className="inputLabel">{t('OnBoarding.owningCompany.country')}</p> <span style={{ color: "red" }}>*</span>
            <InputTypeText
              className="inputText"
              placeholder=""
              value={formData?.page7?.owningCompanyDetails?.countryOfRegistration || ""}
              onChange={(e) => handleInputChange("page7.owningCompanyDetails.countryOfRegistration", e.target.value)}
            />
          </div>
          <div className="inputFlex">
            <p className="inputLabel">{t('OnBoarding.owningCompany.registrationNumber')}</p> <span style={{ color: "red" }}>*</span>
            <InputTypeText
              className="inputText"
              placeholder=""
              value={formData?.page7?.owningCompanyDetails?.businessRegistrationNumber || ""}
              onChange={(e) => handleInputChange("page7.owningCompanyDetails.businessRegistrationNumber", e.target.value)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Section5Page;