import React from "react";
import { Outlet } from "react-router-dom";
import LeftSideBar from "../LeftSidebar";
import { useLocation } from "react-router-dom";
import "./index.css";
import Header from "../Header";

const AppLayout = ({children}:any) => {
  // console.log('AppLayoutchildren: ', children);
  const { pathname } = useLocation();

  const sidebarActiveTab = pathname.replace("/", "");

  const isFullScreenRoute = pathname === "/documentsubmit";
  return (
    <div className={isFullScreenRoute ? "fullScreenLayout" : "mainLayoutSection"}>
      {!isFullScreenRoute && (
        <>
          <div className="mainLayout_Leftsidebar">
            <LeftSideBar name={sidebarActiveTab} />
          </div>
          <div className="mainLayout_HeaderAndContent">
            <div className="mainLayout_Header">
              <Header />
            </div>
            <div className="mainLayout_Content">
              <Outlet />
              {/* {children} */}
            </div>
          </div>
        </>
      )}
      {isFullScreenRoute && (
        <div className="fullScreenContent">
          <Outlet />
          {/* {children} */}
        </div>
      )}
    </div>
  );
};

export default AppLayout;
