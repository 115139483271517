// persistStore.ts
import storage from "redux-persist/lib/storage";

export const documentSubmitFormConfig = {
  key: "documentSubmitForm",
  storage,
  whitelist: ["documentSubmitForm"],
};
export const section1config = {
  key: "section1",
  storage,
  whitelist: ["section1"],
};
export const section2 = {
  key: "section2",
  storage,
  whitelist: ["section2"],
};
export const section3 = {
  key: "section3",
  storage,
  whitelist: ["section3"],
};
export const section4 = {
  key: "section4",
  storage,
  whitelist: ["section4"],
};
export const section5 = {
  key: "section5",
  storage,
  whitelist: ["section5"],
};
export const section6 = {
  key: "section6",
  storage,
  whitelist: ["section6"],
};
export const section7 = {
  key: "section7",
  storage,
  whitelist: ["section7"],
};
export const currenciesConfig = {
  key: "currencies",
  storage,
  whitelist: ["currencies"],
};