import React from "react";
import { Box, CircularProgress, Typography, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DeleteBlackIcon } from "../../constants/Images";

type DocumentUploadProps = {
  label: string;
  instructions?: string;
  file: File | null | File[] | undefined; // Accepts both single and multiple files
  buttonText: string;
  inputId: string;
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFileRemove?: (index: number) => void; // Function to remove a file
  onButtonClick: () => void;
  fileUploadIcon: string;
  loading: boolean;
};

const DocumentUpload: React.FC<DocumentUploadProps> = ({
  label,
  instructions,
  file,
  buttonText,
  inputId,
  onFileChange,
  onFileRemove,
  onButtonClick,
  fileUploadIcon,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        border: "2px dashed #D7F0FF",
        borderRadius: "8px",
        padding: "16px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        mt: 2,
        mb: 2,
        width: "100%",
      }}
    >
      <img
        src={fileUploadIcon}
        style={{ width: 40, height: 40, marginBottom: 10 }}
        alt="File upload icon"
      />
      {
        file ?
          <>
          {Array.isArray(file) ? (
        file.length > 0 && (
          <Box sx={{ mt: 2, width: "100%" }}>
            {file.map((f, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  background: "#f7f7f7",
                  padding: "8px",
                  borderRadius: "6px",
                  mb: 1,
                }}
              >
                <Typography className="textFont" sx={{ fontSize: "14px", fontWeight: '500', marginLeft: 1 }}>{f.name}</Typography>
                {onFileRemove && (
                  <IconButton
                    size="small"
                    onClick={() => onFileRemove(index)}
                    sx={{ color: "#ff4d4d" }}
                  >
                    <img src={DeleteBlackIcon} alt="delete icon" width={12} height={12} />
                  </IconButton>
                )}
              </Box>
            ))}
          </Box>
        )
      ) : (
        file && <Typography className="textFont" style={{ marginBottom: 8 }}>{(file as File).name}</Typography>
      )}</> :
          <Typography className="textFont">{t("OnBoarding.fileChoosen")}</Typography>
      }

      {instructions && (
        <p className="textFont" style={{ color: "#ADB6C0", marginBottom: 4 }}>
          {instructions}
        </p>
      )}

      {/* Display multiple files if applicable */}
      

      <div className="chooseFileBtn">
        {loading ? (
          <CircularProgress />
        ) : (
          <button type="button" onClick={onButtonClick}>
            {buttonText}
          </button>
        )}
      </div>

      <input
        type="file"
        id={inputId}
        style={{ display: "none" }}
        multiple={Array.isArray(file)} // Allow multiple file selection if applicable
        onChange={onFileChange}
      />
    </Box>
  );
};

export default DocumentUpload;
