import React from 'react';
import InputTypeText from '../../components/shared/Input/Basic/InputTypeText';
import { DocumentSubmitFormData } from '../../redux/toolkit/documentSubmit/interface';
import { Information } from '../../constants/Images/index';
import { useTranslation } from 'react-i18next';

type Section2Props = {
    formData: DocumentSubmitFormData;
    errors: { [key: string]: string };
    handleInputChange: (fieldPath: string, value: any, index?: number) => void;
    renderEditableField: (label: string, field: string, value: string) => JSX.Element;
};

const Section2: React.FC<Section2Props> = ({ formData, errors, handleInputChange, renderEditableField }) => {
    const { t } = useTranslation();
    const Remitters = formData.page2.remitters || [];
    const Beneficiaries = formData.page2.beneficiaries || [];

    const handleRemitterChange = (value: string, index: number) => {
        const updatedRemitters = [...Remitters];
        updatedRemitters[index] = { name: value };
        handleInputChange('page2.remitters', updatedRemitters);
    };

    const handleBeneficiaryChange = (value: string, index: number) => {
        const updatedBeneficiaries = [...Beneficiaries];
        updatedBeneficiaries[index] = { name: value };
        handleInputChange('page2.beneficiaries', updatedBeneficiaries);
    };

    return (
        <div>
            <form>
                {/* Remitters Section */}
                <div className='inputFlex inputfixcls'>
                    <p style={{ fontWeight: 600, fontSize: 18 }} className='inputLabel'>
                        {t('OnBoarding.top5Remitters')}
                    </p>
                    <span style={{ color: 'red' }}>*</span>
                    <div style={{ display: 'flex', gap: 14, marginTop: 10 }}>
                        <img src={Information} alt='icon' width={16} height={16} style={{ marginTop: 4 }} />
                        <div>
                            <p>{t('OnBoarding.remitterDescription.title')}</p>
                            <p>{t('OnBoarding.remitterDescription.info1')}</p>
                            <p>{t('OnBoarding.remitterDescription.info2')}</p>
                            <p>{t('OnBoarding.remitterDescription.info3')}</p>
                        </div>
                    </div>
                    {[...Array(5)].map((_, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                            <span>{index + 1}.</span>
                            <div style={{ width: '100%' }}>
                                <InputTypeText
                                    className='inputText'
                                    placeholder={t('OnBoarding.remitter')}
                                    value={Remitters[index]?.name || ''}
                                    onChange={(e) => handleRemitterChange(e.target.value, index)}
                                />
                            </div>
                        </div>
                    ))}
                    <p>{t('OnBoarding.fillAsManyAsPossible')}</p>
                    {errors.top3Remitters && <p className='error-message'>{errors.top3Remitters}</p>}
                </div>

                {/* Beneficiaries Section */}
                <div className='inputFlex inputfixcls'>
                    <p style={{ fontWeight: 600, fontSize: 18 }} className='inputLabel'>
                        {t('OnBoarding.top5Beneficiaries')}
                    </p>
                    <span style={{ color: 'red' }}>*</span>
                    <div style={{ display: 'flex', gap: 14, marginTop: 10 }}>
                        <img src={Information} alt='icon' width={16} height={16} style={{ marginTop: 4 }} />
                        <div>
                            <p>{t('OnBoarding.beneficiaryDescription.title')}</p>
                            <p>{t('OnBoarding.beneficiaryDescription.info1')}</p>
                            <p>{t('OnBoarding.beneficiaryDescription.info2')}</p>
                        </div>
                    </div>
                    {[...Array(5)].map((_, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                            <span>{index + 1}.</span>
                            <div style={{ width: '100%' }}>
                                <InputTypeText
                                    className='inputText'
                                    placeholder={t('OnBoarding.beneficiary')}
                                    value={Beneficiaries[index]?.name || ''}
                                    onChange={(e) => handleBeneficiaryChange(e.target.value, index)}
                                />
                            </div>
                        </div>
                    ))}
                    <p>{t('OnBoarding.fillAsManyAsPossible')}</p>
                    {errors.top3Beneficiaries && <p className='error-message'>{errors.top3Beneficiaries}</p>}
                </div>
            </form>
        </div>
    );
};

export default Section2;
