import React, { useEffect } from 'react';
import { FormControl, RadioGroup, FormControlLabel, Radio, Autocomplete, TextField } from '@mui/material';
import { DocumentSubmitFormData } from '../../redux/toolkit/documentSubmit/interface';
import InputTypeText from '../../components/shared/Input/Basic/InputTypeText';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Information } from '../../constants/Images';

type Section7Props = {
    handleInputChange: (fieldPath: string, value: any, index?: number) => void;
    errors: { [key: string]: string };
    formData: DocumentSubmitFormData;
};

const Section7: React.FC<Section7Props> = ({ formData, handleInputChange, errors }) => {
    const { t } = useTranslation();
    const { currencies } = useSelector((state: RootState) => state.currencies);
    const countries = currencies.map((ele) => ele.country);

    useEffect(() => {
        if (formData?.page6?.requestBusinessCard === undefined) {
            handleInputChange('page6.requestBusinessCard', false); // Default value
        }
    }, [formData, handleInputChange]);

    return (
        <div>
            <div className="inputFlex">
                <div>
                    <p className="inputLabel">{t('OnBoarding.requestBusinessCard')}</p> <span style={{ color: 'red' }}>*</span>
                </div>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="debit-card-radio-group"
                        value={
                            formData?.page6?.requestBusinessCard === true
                                ? "yes"
                                : formData?.page6?.requestBusinessCard === false
                                    ? "no"
                                    : ""
                        }
                        onChange={(e) => handleInputChange('page6.requestBusinessCard', e.target.value === 'yes')}
                    >
                        <FormControlLabel value="yes" control={<Radio />} label={t('OnBoarding.yes')} />
                        <FormControlLabel value="no" control={<Radio />} label={t('OnBoarding.no')} />
                    </RadioGroup>
                </FormControl>
                {errors.requestBusinessCard && <p className="error-message">{errors.requestBusinessCard}</p>}
            </div>

            <div className="inputFlex">
                <p className="inputLabel">
                    {t('OnBoarding.monthlyExpectedCardSpend', { currency: formData.page1.selectedCurrency || "EUR" })}
                </p>
                <span style={{ color: 'red' }}>*</span>
                <div style={{ display: 'flex', gap: 14, marginTop: 10 }}>
                    <img src={Information} alt="icon" width={16} height={16} style={{ marginTop: 4 }} />
                    <div>
                        <p style={{ marginBottom: 5 }}>{t('OnBoarding.enterAmountNote')}</p>
                    </div>
                </div>
                <InputTypeText
                    className="inputText"
                    placeholder=""
                    type='number'
                    value={formData?.page6?.expectedMonthlyCardSpend}
                    onChange={(e) => handleInputChange('page6.expectedMonthlyCardSpend', e.target.value)}
                />
                {errors.expectedMonthlyCardSpend && <p className="error-message">{errors.expectedMonthlyCardSpend}</p>}
            </div>

            <div className="inputFlex">
                <div>
                    <p className="inputLabel">{t('OnBoarding.confirmCardholder')}</p> <span style={{ color: 'red' }}>*</span>
                </div>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="debit-card-radio-group"
                        value={formData?.page6?.cardholder}
                        onChange={(e) => handleInputChange('page6.cardholder', e.target.value)}
                    >
                        <FormControlLabel value="Owner" control={<Radio />} label={t('OnBoarding.owner')} />
                        <FormControlLabel value="Director" control={<Radio />} label={t('OnBoarding.director')} />
                        <FormControlLabel value="Employee" control={<Radio />} label={t('OnBoarding.employee')} />
                    </RadioGroup>
                </FormControl>
                {errors.cardholder && <p className="error-message">{errors.cardholder}</p>}
            </div>

            <div className="inputFlex">
                <p className="inputLabel">{t('OnBoarding.cardholderResidenceCountries')}</p> <span style={{ color: 'red' }}>*</span>
                <Autocomplete
                    multiple
                    options={countries}
                    value={formData?.page6?.cardholderResidenceCountries || []}
                    onChange={(event, newValue) =>
                        handleInputChange('page6.cardholderResidenceCountries', newValue)
                    }
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" style={{ borderRadius: 20 }} />
                    )}
                />
                {errors.cardholderResidenceCountries && <p className="error-message">{errors.cardholderResidenceCountries}</p>}
            </div>
        </div>
    );
};

export default Section7;
