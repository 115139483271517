import { BeneficiaryInformation, CurrencyOption, DirectorInformation, DocumentSubmitFormData, Section1, Section2, Section3, Section4, Section5, Section6, Section7, Section8 } from "./interface";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchCompanyInfoByCVR, fetchUserInfo } from "./operation";
import { apiHelper } from "../../../api helper";
import { AddBeneficiary, UpdateBeneficiary } from "../beneficiaries/operation";

const initialState: {
    currencies: CurrencyOption[];
    loading: boolean;
    error: string | null;
} = {
    currencies: [],
    loading: false,
    error: null,
};
const token = localStorage.getItem("token")

// Define the initial state for the form
const initialStateDocumentSubmitForm: DocumentSubmitFormData = {
    onboardingProgress: 0,
    onboardingStatus: '',
    // companyInfo: {
    //     companyName: "",
    //     companyRegistrationNumber: "",
    //     countryOfIncorporation: "",
    //     businessDescription: "",
    //     averageAnnualRevenueEUR: "",
    //     numberOfEmployees: "",
    //     industry: [],
    //     companyOperations: "",
    //     companyAddress: "",
    //     websiteLink: "",
    // },
    // questionnaireInfo: {
    //     countriesSendingMoneyTo: [],
    //     industry: [],
    //     estimatedAnnualNumberOfPayments: [],
    //     incomingCurrencies: [],
    //     outgoingCurrencies: [],
    //     monthlyNumberOfTransctions: [],
    //     intrestedInDebitCard: false,
    //     top5Customers: ["", "", "", "", ""],
    //     top5Suppliers: ["", "", "", "", ""],
    //     purposeOfOpeningBusinessAccount: "",
    //     countriesPayingTaxesTo: [],
    //     top3Remitters: ["", "", ""],
    //     top3Beneficiaries: ["", "", ""],
    //     directorInformation: [],
    //     directorSoleOwner: "false",
    //     shareHoldersName: "",
    //     registrationNumber: "",
    //     percentages: "",
    //     proofOfAddress: null,
    //     uploadPassportPhoto: null,

    // },
    // attachedDocuments: {
    //     sourceOfWealth: null,
    //     proofOfNatureOfBusiness: null,
    //     businessRegistrationCertificate: null,
    // },
    page1: {
        companyType: "",
        companyName: "",
        businessRegistrationNumber: "",
        countryOfIncorporation: "",
        businessRegistryLink: "",
        businessDescription: "",
        expectedAnnualRevenue: "",
        selectedCurrency: '',
        numberOfEmployees: 0
    },
    page2: {
        remitters: [],
        beneficiaries: [],
    },
    page3: {
        marketingDescription: "",
        websiteUrl: "",
        facebookPage: "",
        instagramName: "",
    },
    page4: {
        incomingPaymentsPerMonth: "",
        outgoingPaymentsPerMonth: "",
        monthlyIncomingPayments: 0,
        monthlyOutgoingPayments: 0,
    },
    page5: {
        incomingPaymentCountries: [],
        outgoingPaymentCountries: [],
        incomingCurrencies: [],
        outgoingCurrencies: [],
    },
    page6: {
        requestBusinessCard: false,
        expectedMonthlyCardSpend: '',
        cardholder: "",
        cardholderResidenceCountries: [],
    },
    page7: {
        owningCompanyDetails: {
            companyName: "",
            countryOfRegistration: "",
            businessRegistrationNumber: ""
        },
        isDirectorSoleOwner: false,
        isUboSameAsDirector: false,
        directors: [],
        beneficialOwners: [],
        hasMultipleOwners: false,
        isOwnedByAnotherCompany: false,
    },
    page8: {
        personalTaxStatement: null,
        bankStatement: null,
        proofOfNatureOfBusiness: null,
        businessRegistrationCertificate: null,
        additionalDocument: null,
        isSubmitted: false,
    }
};

const section1initialstate: Section1 = {
    companyType: "",
    companyName: "",
    businessRegistrationNumber: "",
    countryOfIncorporation: "",
    businessRegistryLink: "",
    businessDescription: "",
    expectedAnnualRevenue: "",
    numberOfEmployees: 0
};
const section2initialstate: Section2 = {
    remitters: [],
    beneficiaries: [],
};
const section3initialstate: Section3 = {
    marketingDescription: "",
    websiteUrl: "",
    facebookPage: "",
    instagramName: "",
};
const section4initialstate: Section4 = {
    incomingPaymentsPerMonth: "",
    outgoingPaymentsPerMonth: "",
    monthlyIncomingPayments: 0,
    monthlyOutgoingPayments: 0,
};
const section5initialstate: Section5 = {
    owningCompanyDetails: {
        companyName: "",
        countryOfRegistration: "",
        businessRegistrationNumber: ""
    },
    isDirectorSoleOwner: false,
    isUboSameAsDirector: false,
    directors: [],
    beneficialOwners: [],
    hasMultipleOwners: false,
    isOwnedByAnotherCompany: false,
};
const section6initialstate: Section6 = {
    incomingPaymentCountries: [],
    outgoingPaymentCountries: [],
    incomingCurrencies: [],
    outgoingCurrencies: [],
};
const section7initialstate: Section7 = {
    requestBusinessCard: false,
    expectedMonthlyCardSpend: '',
    cardholder: '',
    cardholderResidenceCountries: []
};
const section8initialstate: Section8 = {
    personalTaxStatement: null,
    bankStatement: null,
    proofOfNatureOfBusiness: null,
    businessRegistrationCertificate: null,
    additionalDocument: null,
};

export const fetchCurrencies = createAsyncThunk<
    CurrencyOption[],
    void,
    { rejectValue: string }
>('currencies/fetchCurrencies', async (_, { rejectWithValue }) => {
    try {
        const response = await apiHelper('onboarding/get-valid-currencies', 'get', {}, { Authorization: `Bearer ${token}`, });
        return response.data.data;
    } catch (error: any) {
        return rejectWithValue('Failed to fetch currencies');
    }
});

const documentSubmitFormSlice = createSlice({
    name: 'documentSubmitForm',
    initialState: initialStateDocumentSubmitForm,
    reducers: {
        setDocumentSubmitFormData: (
            state,
            action: PayloadAction<Partial<DocumentSubmitFormData>>
        ) => {
            return { ...state, ...action.payload };
        },
        resetDocumentSubmitFormData: () => initialStateDocumentSubmitForm,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
            state.page1 = action.payload.page1;
            state.page2 = action.payload.page2;
            state.page3 = action.payload.page3;
            state.page4 = action.payload.page4;
            state.page5 = action.payload.page5;
            state.page6 = action.payload.page6;
            state.page7 = action.payload.page7;
        });

        builder.addCase(fetchUserInfo.rejected, (state, action) => {
            console.error('Failed to fetch user info:', action.payload);
        });
    },
});

const currencySlice = createSlice({
    name: 'currencies',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCurrencies.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCurrencies.fulfilled, (state, action: PayloadAction<CurrencyOption[]>) => {
                state.currencies = action.payload;
                state.loading = false;
            })
            .addCase(fetchCurrencies.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch currencies';
            });
    },
});

const Section1Slice = createSlice({
    name: "section1",
    initialState: section1initialstate,
    reducers: {
        setSection1Data: (
            state,
            action: PayloadAction<Partial<Section1>>
        ) => {

            return { ...state, ...action.payload };
        },
        resetSection1Data: () => section1initialstate,
    },
});

const Section2Slice = createSlice({
    name: "section2",
    initialState: section2initialstate,
    reducers: {
        setSection2Data: (
            state,
            action: PayloadAction<Partial<Section2>>
        ) => {
            return { ...state, ...action.payload };
        },
        resetSection2Data: () => section2initialstate,
    },
});

const Section3Slice = createSlice({
    name: "section3",
    initialState: section3initialstate,
    reducers: {
        setSection3Data: (
            state,
            action: PayloadAction<Partial<Section3>>
        ) => {
            return { ...state, ...action.payload };
        },
        resetSection3Data: () => section3initialstate,
    },
});
const Section4Slice = createSlice({
    name: "section4",
    initialState: section4initialstate,
    reducers: {
        setSection4Data: (
            state,
            action: PayloadAction<Partial<Section4>>
        ) => {
            return { ...state, ...action.payload };
        },
        resetSection4Data: () => section4initialstate,
    },
});
const Section5Slice = createSlice({
    name: 'section5',
    initialState: section5initialstate,
    reducers: {
        setSection5Data: (state, action: PayloadAction<Partial<Section5>>) => {
            return { ...state, ...action.payload };
        },
        addDirector: (state, action: PayloadAction<DirectorInformation>) => {
            state.directors.push(action.payload);
        },
        addBeneficiary: (state, action: PayloadAction<BeneficiaryInformation>) => {
            state.beneficialOwners.push(action.payload);
        },
        updateDirector: (state, action: PayloadAction<{ index: number; data: DirectorInformation }>) => {
            state.directors[action.payload.index] = action.payload.data;
        },
        updateBeneficiary: (state, action: PayloadAction<{ index: number; data: BeneficiaryInformation }>) => {
            state.beneficialOwners[action.payload.index] = action.payload.data;
        },
        deleteDirector: (state, action: PayloadAction<number>) => {
            state.directors.splice(action.payload, 1);
        },
        deleteBeneficiary: (state, action: PayloadAction<number>) => {
            state.beneficialOwners.splice(action.payload, 1);
        },
        resetSection5Data: () => section5initialstate,
    },
});
const Section6Slice = createSlice({
    name: "section6",
    initialState: section6initialstate,
    reducers: {
        setSection6Data: (
            state,
            action: PayloadAction<Partial<Section6>>
        ) => {
            return { ...state, ...action.payload };
        },
        resetSection6Data: () => section6initialstate,
    },
});
const Section7Slice = createSlice({
    name: "section7",
    initialState: section7initialstate,
    reducers: {
        setSection7Data: (
            state,
            action: PayloadAction<Partial<Section7>>
        ) => {
            return { ...state, ...action.payload };
        },
        resetSection7Data: () => section7initialstate,
    },
});


// Export actions
export const { setDocumentSubmitFormData, resetDocumentSubmitFormData } =
    documentSubmitFormSlice.actions;
export const { setSection1Data, resetSection1Data } =
    Section1Slice.actions;
export const { setSection2Data, resetSection2Data } =
    Section2Slice.actions;
export const { setSection3Data, resetSection3Data } =
    Section3Slice.actions;
export const { setSection4Data, resetSection4Data } =
    Section4Slice.actions;
export const { setSection5Data, addDirector, updateDirector, deleteDirector, resetSection5Data } = Section5Slice.actions;
export const { setSection6Data, resetSection6Data } =
    Section6Slice.actions;
export const { setSection7Data, resetSection7Data } =
    Section7Slice.actions;

// Export reducer
export default {
    currencySlice,
    documentSubmitFormSlice,
    Section1Slice,
    Section2Slice,
    Section3Slice,
    Section4Slice,
    Section5Slice,
};